<template>
  <wiskModal :visible="!!editAction && user.god_mode" @hide="setGlobalAction({ type: 'wiskDistributorEdit', action: null })" :warning="!!(wiskDistributor?.archived)"
    size="lg" :hideFooter="!!wiskDistributor.id" :title="modalTitle" okText="Save" @ok="save" :okDisabled="!valid">

    <template v-slot:modal-header-extra>
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" v-if="wiskDistributor && wiskDistributor.id" />
      <b-form-radio-group style="margin-left: 5px;" size="sm" buttons v-model="selectedTab" name="selectedTab" button-variant="outline-primary">
        <b-form-radio value="edit"> {{ translations.txtGenericEdit }} </b-form-radio>
        <b-form-radio value="timeline"> {{ translations.txtGenericTimeline }} </b-form-radio>
      </b-form-radio-group>
    </template>

    <wiskInputGroup v-if="selectedTab === 'edit'" @errorCountChanged="setValidState" style="min-height: 600px;" @operationsChange="onChange" class="family-edit">
      <b-row>
        <b-col cols="12" md="6" lg="4">
          <wiskInput infoTooltipKey="234517a3-4b6c-4f1b-bb54-80818d0a3545" :label="translations.txtGenericTitle" operation="title" :modelValue="wiskDistributor.title" @operation="onSingleChange" required autofocus />
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <wiskSelect infoTooltipKey="e7941667-d264-4a3c-b3a7-a7ed07a44d41" :items="countries" @operation="onSingleChange" :modelValue="wiskDistributor.country" operation="country" :label="translations.txtGenericCountry" trackBy="code" required />
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <wiskSelect infoTooltipKey="9e89dc0f-9eba-463a-b74f-b259fc874b5d" :items="options.wisk_distributors_types" @operation="onSingleChange" :modelValue="wiskDistributor.type" operation="type" :label="translations.txtGenericType" trackBy="id" />
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <wiskInput v-if="wiskDistributor.id" infoTooltipKey="e860ce59-1172-463f-b8da-6f6c8a912aec" :label="translations.txtDistributorReviewed" operation="reviewed" @operation="onSingleChange" inputType="checkbox" :modelValue="wiskDistributor.reviewed" :inputClass="(wiskDistributor.reviewed && 'text-success') || ''" />
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <wiskInput v-if="wiskDistributor.id" infoTooltipKey="b0bc27bb-81b2-4097-b11f-e87889370663" :label="translations.txtDistributorExcluded" operation="excluded" @operation="onSingleChange" inputType="checkbox" :modelValue="wiskDistributor.excluded" :inputClass="(wiskDistributor.excluded && 'text-danger') || ''" />
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <wiskInput v-if="wiskDistributor.id" infoTooltipKey="e47a8087-0319-4ea4-94ff-8fb9747bee1b" :label="translations.txtDistributorSupplyIntegration" operation="distributor_integration" @operation="onSingleChange" inputType="checkbox" :modelValue="wiskDistributor.distributor_integration" :inputClass="(wiskDistributor.distributor_integration && 'text-primary') || ''" />
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <wiskInput v-if="wiskDistributor.id" infoTooltipKey="152a5ff3-e682-4ab3-9872-dcf353aa8fa8" :label="translations.txtDistributorNotifyNewDistributors" operation="should_notify_new_distributors" @operation="onSingleChange" inputType="checkbox" :modelValue="wiskDistributor.should_notify_new_distributors" :inputClass="(wiskDistributor.should_notify_new_distributors && 'text-primary') || ''" />
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <wiskInput v-if="wiskDistributor.id" infoTooltipKey="ce88586b-c2a1-43e4-9881-2dfd413105c2" :label="translations.txtDistributorWiskInstantLearning" operation="use_instant_learning" @operation="onSingleChange" inputType="checkbox" :modelValue="wiskDistributor.use_instant_learning" :inputClass="(wiskDistributor.use_instant_learning && 'text-primary') || ''" />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <wiskGrid parentGridName="WiskDistributorEditVenues" :rowData="venues" :columnDefs="venuesGridColumns" trackBy="venue_id" :gridStyle="{ height: 'calc(100vh - 265px)' }" />
        </b-col>
      </b-row>
    </wiskInputGroup>

    <timeline v-if="selectedTab === 'timeline'" parentGridName="WiskDistributorEditTimeline" :filters="[{ type: 'wisk_distributor', id: wiskDistributor.id }]" />

    <wiskLoading :loading="loading" />
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import timeline from '@/components/timeline/Timeline'
import wiskGrid from '@/components/grids/WiskGrid'
import api from '@/api'
import { getWiskDistributorVenuesGridColumns } from './gridOptions'

export default {
  name: 'WiskDistributorEdit',
  components: { timeline, wiskGrid },
  props: {
    editAction: Object
  },
  data() {
    return {
      wiskDistributor: {},
      operations: [],
      loading: false,
      valid: true,
      selectedTab: 'edit',
      venuesGridColumns: [],
      venues: []
    }
  },
  computed: {
    ...mapState(['translations', 'user', 'options']),
    ...mapGetters(['countries']),
    modalTitle() {
      if (this.wiskDistributor) {
        let archived = this.wiskDistributor.archived ? ` - (${this.translations.txtGenericArchived})` : ''
        return this.wiskDistributor.id ? `${this.wiskDistributor.title} (${this.translations.txtDistributorWisk})${archived}` : this.translations.txtDistributorWiskNew + archived
      }
      return ''
    },
    pageActions() {
      return [
        { key: 2, title: this.translations.txtGenericArchive, variant: 'danger', hide: this.wiskDistributor.archived, action: this.archive },
        { key: 3, title: this.translations.txtGenericRestore, variant: 'success', hide: !this.wiskDistributor.archived, action: this.restore }
      ]
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    onChange(operations) {
      this.operations = [...operations]
    },
    archive() {
      this.save([{ type: 'archive', value: true }])
    },
    restore() {
      this.save([{ type: 'archive', value: false }])
    },
    onSingleChange(operation) {
      if (this.wiskDistributor && this.wiskDistributor.id) {
        this.save(operation)
      }
    },
    getData(id) {
      api.wiskDistributors(id).then(wiskDistributor => {
        this.wiskDistributor = wiskDistributor
        this.venues = wiskDistributor.distributor_venue_info
      })
    },
    save(operation) {
      if (this.wiskDistributor && (this.valid || operation)) {
        this.loading = true

        api.updateWiskDistributor(this.wiskDistributor.id, operation || this.operations).then(updated => {
          this.wiskDistributor = updated

          if (typeof this.editAction.onChange === 'function') {
            this.editAction.onChange(updated)
          }

          if (!operation) {
            this.setGlobalAction({ type: 'wiskDistributorEdit', action: null })

            setTimeout(() => {
              this.setGlobalAction({ type: 'wiskDistributorEdit', action: { id: updated.id } })
            }, 0)
          }
        }).finally(() => {
          this.loading = false
          this.operations = []
        })
      }
    }
  },
  created() {
    this.venuesGridColumns = getWiskDistributorVenuesGridColumns(this.translations, this.editAction.id)
  },
  watch: {
    editAction: {
      handler() {
        if (this.editAction && this.editAction.id) {
          this.getData(this.editAction.id)
        } else {
          this.wiskDistributor = { id: 0 }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss"></style>
