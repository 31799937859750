/*

 - actions to start with an action verb - getSomething, setSomething, doSomething - prepares what to send to mutation
 - mutations to start with word mutate - mutateSomething - must simply set what it receives to the state, no business logic
 - getters and state to have no prefix and to be as clear as possible - something{}, somethings[]

*/
import { onValue } from 'firebase/database'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import merge from 'lodash.merge'
import isFinite from 'lodash.isfinite'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'
import uniqWith from 'lodash.uniqwith'
import isEmpty from 'lodash.isempty'
import { Settings } from 'luxon'
import mixpanel from 'mixpanel-browser'
import posthogjs from 'posthog-js'
import Highcharts from 'highcharts'
// import { enable as enableDarkMode, disable as disableDarkMode, auto as followSystemColorScheme, isEnabled as isDarkReaderEnabled, setFetchMethod as setDarkReaderFetchMethod , exportGeneratedCSS } from 'darkreader'
import { guid, prepareItemsForWiskInput, compareNumbers, arrayToObjectById, setTimeoutAsync, venueIdToUrl, downloadObjectAsJson, isLocalhost } from '@/modules/utils'
import { setPreferences, getPreferences } from '@/modules/storage'
import { initDB, cleanupDB } from '@/modules/db'
import translations from '@/modules/translations'
import infoTooltipsById from '@/modules/infoTooltips'
import gridViewsCore from '@/components/grids/views'
import { AUTH, subscribeToDocument, subscribeToCollection, onlineCheckReference } from '@/firebase'
import router from '@/router'
import api from '@/api'

let modalCounter = 0,
  getGlobalOptionsUnsubscribe = null,
  currentVenueId = null,
  debug = 0

// setDarkReaderFetchMethod(window.fetch)

const redirectToSignin = () => {
  let authPages = ['signup', 'signin', 'auth', 'deep-link', 'public'],
    found = 0

  authPages.forEach(path => {
    if (window.location.pathname.includes(path)) {
      found++
    }
  })
  if (!found) {
    let currentRoute = (router && router.currentRoute && router.currentRoute.value) || {},
      path = currentRoute.path || ''

    if (path && path.includes('logout')) {
      path = ''
    }
    let query = { ...currentRoute.query },
      url = '/signin'

    if (path) {
      query.returnUrl = path
    }

    Object.keys(query).forEach((key, index) => {
      url += index ? '&' : '?'
      url += `${key}=${query[key]}`
    })

    window.location.href = url
  }
}

const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)'),
  linkElementId = 'dark-mode'

const applyDarkMode = () => {
  if (!document.getElementById(linkElementId)) {
    let linkElement = document.createElement('link')
    linkElement.rel = 'stylesheet'
    linkElement.id = linkElementId
    linkElement.href = '/dark-reader.css'
    document.body.appendChild(linkElement)
  }
}

const applyLightMode = () => {
  const linkElement = document.getElementById(linkElementId)
  if (linkElement) {
    linkElement.remove()
  }
}

const handleColorSchemeChange = event => {
  event.matches ? applyDarkMode() : applyLightMode()
}

const setupFirebaseEvents = (dispatch, state) => {
  onValue(onlineCheckReference, snap => {
    dispatch('setOnlineState', snap.val())
  })

  onAuthStateChanged(AUTH, firebaseUser => {
    dispatch('setLoadingScreenVisible', false)
    window.firebaseUser = firebaseUser

    if (firebaseUser) {
      dispatch('setFirebaseUser', firebaseUser)

      api
        .authenticate(state.waitingForVenue, 'firebase onAuthStateChanged event')
        .then(wiskUser => {
          if (wiskUser) {
            const production = window.location.hostname === 'web.wisk.ai'
            if (!isLocalhost) {
              if (!production || (production && !(wiskUser.is_wisk_user || wiskUser.god_mode))) {
                mixpanel.identify(wiskUser.id)
                mixpanel.people.set({
                  $email: wiskUser.email,
                  $last_name: wiskUser.last_name,
                  $first_name: wiskUser.first_name
                })
                posthogjs.identify(wiskUser.id, {
                  email: wiskUser.email,
                  last_name: wiskUser.last_name,
                  first_name: wiskUser.first_name
                })
                if (wiskUser.venue) {
                  mixpanel.register({
                    venue_id: wiskUser.venue.id,
                    organization_id: wiskUser.venue.organization_id
                  })
                  posthogjs.register({
                    venue_id: wiskUser.venue.id,
                    organization_id: wiskUser.venue.organization_id
                  })
                  posthogjs.group('venue', wiskUser.venue.id, {
                    name: wiskUser.venue.title,
                    id: wiskUser.venue.id
                  })
                  posthogjs.group('organization', wiskUser.venue.organization_id, {
                    id: wiskUser.venue.organization_id
                  })
                }
              } else {
                mixpanel.disable()
                posthogjs.opt_out_capturing()
              }
            }
            if (wiskUser.venue && wiskUser.venue.id && !state.signupFlow) {
              dispatch('setCurrentVenue', { venue: wiskUser.venue, caller: 'authenticate wiskUser.venue' })
            } else {
              dispatch('setCurrentVenue', { venue: { id: 0, subscription: {} }, caller: 'authenticate no venue yet' })
              setTimeout(() => {
                dispatch('setLoadingScreenVisible', false)
              }, 500)
            }
          }
          dispatch('setCurrentUser', wiskUser)
          dispatch('handleReturnUrl')
        })
        .catch(error => {
          console.log('error', error)
          redirectToSignin()
        })
    } else {
      redirectToSignin()
    }
  })
}

export default {
  start({ commit, dispatch, state, getters }) {
    window.WiskGlobals = window.WiskGlobals || { currency: '$' }
    commit('mutateUIEditMode', !!getPreferences('uiEditModeActive'))
    dispatch('setDarkModeTheme', getPreferences('darkModeTheme', 0))

    if (getGlobalOptionsUnsubscribe) {
      getGlobalOptionsUnsubscribe()
    }

    dispatch('getGlobalOptions').then(() => {
      setupFirebaseEvents(dispatch, state, getters)
      dispatch('setTranslations')
      dispatch('loadInfoTooltips')
    })
  },
  setDarkModeTheme({ commit }, payload) {
    darkModeMediaQuery.removeEventListener('change', handleColorSchemeChange)

    if (payload === 0) {
      applyLightMode()
    } else if (payload === 1) {
      applyDarkMode()
    } else if (payload === 2) {
      darkModeMediaQuery.addEventListener('change', handleColorSchemeChange)
      darkModeMediaQuery.matches ? applyDarkMode() : applyLightMode()
    }

    commit('mutateDarkModeTheme', payload)
  },
  loadInfoTooltips({ commit }) {
    commit('mutateInfoTooltipsById', infoTooltipsById)
  },
  saveInfoTooltip({ commit, state }, payload) {
    commit('mutateInfoTooltipsById', { ...state.infoTooltipsById, [payload.id]: payload })
    commit('mutateInfoTooltipsDirty', true)
  },
  downloadTooltipsFile({ state, commit }) {
    downloadObjectAsJson(state.infoTooltipsById, 'infoTooltips.json')
    commit('mutateInfoTooltipsDirty', false)
  },
  async reset({ commit, dispatch }) {
    commit('mutateResetState')
    dispatch('start')
  },
  setCurrentUser({ commit, dispatch }, user) {
    commit('mutateCurrentUser', user)
    dispatch('setCurrentPermissions')
    dispatch('setPageViewPermissions')
    dispatch('getGridViewsCore')
    setPreferences('currentUserId', user.id)
  },
  setCurrentVenue({ commit, dispatch, state }, { venue, caller }) {
    console.log('setCurrentVenue', `caller: ${caller}`, JSON.parse(JSON.stringify(venue)))
    window.WiskGlobals = window.WiskGlobals || {}

    if (venue.timezone && (state.user?.is_wisk_user || state.user?.god_mode)) {
      Settings.defaultZone = venue.timezone
      Settings.defaultLocale = state.user.language || 'en'

      Highcharts.setOptions({
        time: {
          timezone: venue.timezone
        }
      })
      window.WiskGlobals.timezoneDirty = true
    }

    window.WiskGlobals.venueBusinessDayStartHour = venue.business_day_starting_hour || 0

    if (typeof venue.last_locked_inventory_date === 'string') {
      venue.last_locked_inventory_date = new Date(venue.last_locked_inventory_date)
    }

    commit('mutateCurrentVenue', venue)
    dispatch('setCurrentFeatures')
    dispatch('setPageViewPermissions')

    if (!state.signupFlow && venue && venue.id && venue.id !== currentVenueId) {
      dispatch('getPOSItemsExtraData')
      dispatch('loadVenuesHighLevel')
      dispatch('getInventories')
      dispatch('getShoppingCart')
      dispatch('getDistributorPrices')
      dispatch('getMovementExtraLineReasons')

      currentVenueId = venue.id
    }
  },
  async resetSyncProviderLocalPersistence({ state }, venue) {
    venue = venue || state.venue

    await cleanupDB(venue.id)

    if (venue.parent_venue_id) {
      await cleanupDB(venue.parent_venue_id)
    }

    await setTimeoutAsync(100)

    //refreshing the page
    window.location.replace('/')
  },
  getGlobalOptions({ commit, dispatch, state }) {
    return new Promise(resolve => {
      getGlobalOptionsUnsubscribe = subscribeToDocument('settings/global', result => {
        if (result) {
          commit('mutateGlobalOptions', { ...result, loaded: true })

          let permissions = result.permissions || []
          permissions.sort((a, b) => compareNumbers(a.id, b.id))
          commit('mutatePermissions', permissions)

          if (state.user) {
            dispatch('setCurrentPermissions')
          }
          if (state.venue) {
            dispatch('setCurrentFeatures')
          }

          resolve()
        }
      })
    })
  },
  setCurrentPermissions({ commit, state }) {
    let permissionIds = get(state, 'user.role.permissions', [])

    commit('mutateCurrentPermissions', permissionIds)
  },
  setCurrentFeatures({ commit, state }) {
    let features = get(state, 'venue.subscription.plan_features', []).map(f => ({ ...f })),
      selectedPlan = get(state, 'venue.subscription.selected_plan.type', ''),
      messages = get(state, 'options.plan_features_upgrade', {}),
      messagesByFeature = arrayToObjectById(messages[selectedPlan], 'feature'),
      allPossibleFeatures = [],
      featuresByType = {}

    Object.keys(messages).forEach(key => {
      if (Array.isArray(messages[key])) {
        messages[key].forEach(m => {
          allPossibleFeatures.push(m.feature)
        })
      }
    })

    allPossibleFeatures = uniqWith(allPossibleFeatures, isEqual)

    features.forEach(feature => {
      let found = messagesByFeature[feature.feature_identifier]
      feature.message = (found && found.message) || ''
      feature.enabled = true
    })

    featuresByType = arrayToObjectById(features, 'feature_identifier')

    allPossibleFeatures.forEach(featureType => {
      if (!featuresByType[featureType]) {
        featuresByType[featureType] = { enabled: false }

        let found = messagesByFeature[featureType]
        featuresByType[featureType].message = (found && found.message) || ''
      }
    })
    console.log('featuresByType', featuresByType)

    commit('mutateCurrentFeatures', featuresByType)
  },
  setPageViewPermissions({ commit, state }) {
    if (state.user && state.venue && state.currentPermissionsByType) {
      let godMode = !!state.user.god_mode,
        venue = state.venue,
        deniedAllTopItems = venue?.subscription?.status === 'delinquent' || !state?.user?.user_venues?.length,
        deniedAllUserItems = false,
        permissions = {
          initDone: true,
          all: godMode,
          top: godMode || !deniedAllTopItems,
          userItems: godMode || !deniedAllUserItems,
          //top items
          venues: godMode,
          venue_transfers: !!venue.multivenue_enabled,
          dashboard: !!state.currentPermissionsByType.dashboard_view || godMode,
          venue_bottles: !!state.currentPermissionsByType.item_view || godMode,
          distributors: !!state.currentPermissionsByType.item_view || godMode,
          wisk_distributors: godMode,
          families: !!state.currentPermissionsByType.item_view || godMode,
          inventories: !!state.currentPermissionsByType.inventory_view || godMode,
          independent_inventories: !!state.currentPermissionsByType.independent_inventory_view || godMode,
          reports: !!state.currentPermissionsByType.inventory_view || godMode,
          consumption: !!state.currentPermissionsByType.consumption_view || godMode,
          orders: !!state.currentPermissionsByType.purchase_order_view || godMode,
          purchase_orders: !!state.currentPermissionsByType.purchase_order_view || godMode,
          intakes: !!state.currentPermissionsByType.invoice_view || godMode,
          scanned_invoices: !!state.currentPermissionsByType.invoice_view || godMode,
          transfers: !!state.currentPermissionsByType.invoice_view || godMode,
          movements_summary: !!state.currentPermissionsByType.invoice_view || godMode,
          adjustments: !!state.currentPermissionsByType.invoice_view || godMode,
          sales: !!state.currentPermissionsByType.sale_view || godMode,
          sales_test: godMode,
          pos_items: !!state.currentPermissionsByType.pos_item_view || godMode,
          sub_recipes: !!state.currentPermissionsByType.batch_view,
          variance: !!state.currentPermissionsByType.variance_view || godMode,
          price_changes: !!state.currentPermissionsByType.invoice_view || godMode,
          stock_analysis: !!state.currentPermissionsByType.item_view || godMode,
          cost_changes: !!state.currentPermissionsByType.inventory_view || godMode,
          download_manager: !!state.currentPermissionsByType.invoice_view || godMode,

          multi_venue_analytics: (venue.organization_id && state.venuesHighLevel.filter(v => v.organization_id === venue.organization_id).length > 2) || godMode,

          //groups
          group_items: true, //will be hidden if permissions leave it empty
          group_inventories: true, //will be hidden if permissions leave it empty
          group_orders: true, //will be hidden if permissions leave it empty
          group_reports: true, //will be hidden if permissions leave it empty
          group_sales: true, //will be hidden if permissions leave it empty
          group_transfers: true, //will be hidden if permissions leave it empty

          //user items
          help: !godMode,
          account: true,
          reload: true,
          team: true,
          subscription: !!state.venue.stripe_publishable_key && !!state.venue.stripe_id && (!!state.currentPermissionsByType.billing_manage || godMode),
          venue_settings: !!state.currentPermissionsByType.venue_settings_manage || godMode,
          deep_link: true,
          locations: !!state.currentPermissionsByType.area_manage || godMode,
          venue_switch: !!state.user.user_venues,
          venue_onboarding: true,
          auth: true,
          testpage: true,
          organizations: godMode,
          components_demo: true,
          translations: true,
          public_order: true,
          public_no_credit_card_email: true,
          public_signup_lightspeed: true,
          public_lightspeed_signup: true,
          public_redirect: true,
          signin: true,
          signup: true,
          logout: true,
          login_demo: true,
          dashboard_printable: true,
          churned: !state.user.god_mode && venue?.subscription?.status === 'churned',
          'not-found': true
        }
      commit('mutatePageViewPermissions', permissions)
    }
  },
  async handleReturnUrl({ state }) {
    if (state?.route?.query?.returnUrl) {
      let query = { ...state.route.query }
      delete query.returnUrl

      router
        .replace({
          path: state.route.query.returnUrl,
          query
        })
        .catch(() => {})

      setTimeout(() => {
        venueIdToUrl(state.venue.id)
      }, 0)
    }
  },
  logout({ dispatch }) {
    localStorage.removeItem('X-venue_id')
    venueIdToUrl(null)
    dispatch('setFirebaseUser', null)
    return signOut(AUTH)
  },
  setOnlineState({ commit }, payload) {
    commit('mutateOnlineState', payload)
  },
  setFirebaseUser({ commit }, payload) {
    commit('mutateFirebaseUser', payload)
  },
  setWaitingForVenue({ commit }, payload) {
    commit('mutateWaitingForVenue', payload)
  },
  setSignupFlow({ commit }, payload) {
    commit('mutateSignupFlow', payload)
  },
  setWindowResizeKey({ commit }, payload) {
    commit('mutateWindowResizeKey', payload)
  },
  setDeviceInfo({ commit }, payload) {
    commit('mutateDeviceInfo', payload)
  },
  async setUser({ dispatch, commit, state }, payload) {
    let user = await api.updateUser(payload.id, payload.operation, !!state.signupFlow)

    if (user && state.user && payload.id === state.user.id && !payload.preventLoadConfig) {
      dispatch('setCurrentUser', user)
    }

    commit('mutateWaitingForVenue', payload.preventLoadConfig)

    return user
  },
  refreshCurrentUser({ dispatch, state }) {
    api
      .authenticate()
      .then(wiskUser => {
        if (wiskUser) {
          if (wiskUser.venue && wiskUser.venue.id && !state.signupFlow) {
            dispatch('setCurrentVenue', { venue: wiskUser.venue, caller: 'authenticate wiskUser.venue' })
          } else {
            dispatch('setCurrentVenue', { venue: { id: 0, subscription: {} }, caller: 'authenticate no venue yet' })
            setTimeout(() => {
              dispatch('setLoadingScreenVisible', false)
            }, 500)
          }
        }
        dispatch('setCurrentUser', wiskUser)
      })
      .catch(() => {})
  },
  changeActiveVenue(z, { venueId, userId }) {
    api.venueLogin(venueId, userId)

    window.location.reload()
    // dispatch('reset')
  },
  setSidebarMinimized({ commit }, payload) {
    commit('mutateSidebarMinimized', payload)
  },
  refreshView({ commit, state }) {
    commit('mutateViewRefreshKey', state.viewRefreshKey + 1)
  },
  async setTranslations({ dispatch, commit, state }) {
    let currency = (window.WiskGlobals && window.WiskGlobals.currency) || '$',
      languageKey = get(state, 'user.language', navigator.language.substring(0, 2) || 'EN').toUpperCase()

    Object.keys(translations).forEach(key => {
      if (typeof translations[key] === 'string' && translations[key].includes('$')) {
        translations[key] = translations[key].replace(new RegExp('\\$', 'g'), currency)
      }
    })
    commit('mutateTranslations', merge({}, translations))
    document.querySelector('.mainContainer')?.classList.add('translations-ready')

    subscribeToCollection({
      path: `/localization/languages/${languageKey}`,
      handler: translated => {
        let computed = {}

        translated.forEach(t => {
          if (t.term.startsWith('web.group')) {
            let split = t.term.split('.')
            if (split[1] && split[2]) {
              computed[split[1]] = computed[split[1]] || {}
              computed[split[1]][split[2]] = t.definition
            }
          } else if (t.term.startsWith('web.')) {
            computed[t.term.substring(4)] = t.definition
          }
        })

        let merged = merge({}, translations, computed)

        commit('mutateTranslations', merged)
        dispatch('refreshView')
      }
    })
  },
  async setLoadingScreenVisible({ commit, state }, payload) {
    commit('mutateLoadingScreenVisibility', get(state, 'route.meta.lightWeightPage') ? false : !!payload)
  },
  async setInitialDBLoadingProgress({ commit }, payload) {
    commit('mutateInitialDBLoadingProgress', payload)
  },
  setInitialDataLoadComplete({ commit }, payload) {
    commit('mutateInitialDataLoadComplete', payload)
  },
  notify({ commit }, payload) {
    commit('mutateNotification', payload)
  },
  async getMovements({ commit }) {
    let movements = await api.movements()
    movements.forEach(movement => {
      if (movement && movement.date) {
        movement.date = new Date(movement.date)
      }
    })
    movements.sort((a, b) => compareNumbers(b.date.getTime(), a.date.getTime()))

    commit('mutateMovements', movements)
  },
  async getMovementExtraLineReasons({ commit }) {
    let reasons = await api.movementExtraLineReasons()
    reasons.sort((a, b) => compareNumbers(a.id, b.id))

    commit('mutateMovementExtraLineReasons', reasons)
  },
  async setMovement({ commit }, payload) {
    let movement = await api.updateMovement(payload.id, payload.operation)
    if (movement && movement.date) {
      movement.date = new Date(movement.date)
    }

    commit('mutateMovement', movement)
    return movement
  },
  emailVerified(store, uid) {
    api.emailVerified(uid).finally(() => {
      window.location.href = '/'
    })
  },
  async setInventory({ dispatch }, payload) {
    let updatedInventory = await api.updateInventory(payload.id, payload.operation)

    dispatch('getInventories')
    return updatedInventory
  },
  async openActiveInventory({ dispatch }) {
    let result = await api.startInventory({ started_at: new Date().toISOString() })
    if (result && result.current && result.current.id) {
      dispatch('setGlobalAction', { type: 'inventoryEdit', action: { id: result.current.id } })
    }
  },
  async getInventories({ commit }) {
    let inventories = await api.inventoriesHighLevel()

    inventories.forEach(inventory => {
      if (inventory && (inventory.date || inventory.started_at)) {
        inventory.date = new Date(inventory.date || inventory.started_at)
        inventory.started_at = inventory.date
      }
      if (inventory && inventory.finished_at) {
        inventory.finished_at = new Date(inventory.finished_at)
      }
    })
    inventories.sort((a, b) => compareNumbers(b.date.getTime(), a.date.getTime()))

    commit('mutateInventories', inventories)
  },
  async loadVenues({ commit }) {
    let venues = await api.venues()
    commit('mutateVenues', venues)
  },
  async loadVenuesHighLevel({ commit }) {
    let venuesHighLevel = await api.venuesHighLevel()

    if (Array.isArray(venuesHighLevel)) {
      venuesHighLevel.sort((a, b) => compareNumbers(a.title, b.title))
      commit('mutateVenuesHighLevel', venuesHighLevel)
    }
  },
  async getDistributorPrices({ commit }) {
    commit('mutateDistributorPrices', await api.getDistributorPrices())
  },
  async updateVenue({ commit }, payload) {
    let updated = await api.updateVenue(payload.id, payload.operation)

    commit('mutateVenue', updated)

    return updated
  },
  setCustomField(z, payload) {
    api.updateCustomField(payload.id, payload.operation)
  },
  setGridView({ commit }, payload) {
    let operation = payload.operation,
      data = operation.value,
      id = payload.id

    if (operation.type === 'clear') {
      delete operation.value

      data = { id, REMOVE: true }
    }

    commit('mutateGridView', data)

    api.updateWebView(id, operation)
  },
  setGridViewCore({ commit, dispatch, state }, payload) {
    let operation = payload.operation,
      data = operation.value,
      id = payload.id

    if (operation.type === 'clear') {
      delete operation.value

      data = { id, REMOVE: true }
    }

    if (state?.user?.god_mode) {
      console.warn('setGridViewCore - this is only local save in memory, from here the user can download JSON if godmode, open editor, etc', data)
      dispatch('notify', { type: 'info', message: 'Grid view saved to state. System views are not sent to API, only saved in JSON' })
    } else {
      dispatch('notify', { type: 'info', message: state.translations.txtWiskGridViewModificationDisabledMessageSystem })
    }

    commit('mutateGridViewCore', data)
  },
  getGridViewsCore({ commit }) {
    commit('mutateGridViewsCore', gridViewsCore)
  },
  setCustomFiledsByTarget({ commit, getters, state }) {
    if (state.customFields?.length) {
      let result = {}
      for (let i = 0; i < state.customFields.length; i++) {
        let field = state.customFields[i]
        field.type_definition = field.type_definition || {}

        if (field?.type_definition?.type) {
          field.inputTypeAttrs = {
            ...getters.customFieldsTypeTranslations[field.type_definition.type],
            prefix: field.type_definition.symbol,
            label: field.label,
            operation: 'custom_field',
            operationEmpty: 'custom_field_clear',
            items: field.type_definition.type === 'dropdown' ? prepareItemsForWiskInput(field.type_definition.values) : field.type_definition.values,
            customFieldValueWrapper: {
              id: field.id,
              value: {
                type: field.type_definition.type,
                value: null
              }
            }
          }
        }

        if (isFinite(field.type_definition.decimal_places)) {
          field.inputTypeAttrs.decimals = field.type_definition.decimal_places
        }
        if (field.inputTypeAttrs.multiple) {
          field.inputTypeAttrs.callBeforeAddTag = title =>
            new Promise(resolve => {
              let value = { uuid: guid(), title },
                operation = {
                  type: 'type_definition',
                  value: {
                    ...field.type_definition,
                    values: [...field.type_definition.values, value]
                  }
                }

              api.updateCustomField(field.id, operation).then(() => {
                resolve({ ...value })
              })
            })
        }

        result[field.item_type] = result[field.item_type] || []
        result[field.item_type].push(field)
      }

      commit('mutateCustomFieldsByTarget', result)

      let customFieldsByTargetActive = merge({}, state.customFieldsByTarget)

      Object.keys(customFieldsByTargetActive).forEach(key => {
        if (Array.isArray(customFieldsByTargetActive[key])) {
          customFieldsByTargetActive[key] = customFieldsByTargetActive[key].filter(c => c && !c.archived)
        }
      })

      commit('mutateCustomFieldsByTargetActive', customFieldsByTargetActive)
    }
  },
  async updateVenueTransfer(z, payload) {
    let updated = await api.updateVenueTransfer(payload.id, payload.operation)

    return updated
  },
  async getShoppingCart({ commit }) {
    let shoppingCart = await api.getShoppingCart()
    commit('mutateShoppingCart', shoppingCart)
  },
  async addShoppingCart({ dispatch, state }, operation) {
    let newValue = operation.units,
      shoppingCartItem = state.shoppingCart.find(i => i.item_distributor_id === operation.item_distributor_id),
      oldValue = (shoppingCartItem && shoppingCartItem.units) || 0

    let updatedOperation = {
      item_distributor_id: operation.item_distributor_id,
      units: (operation.type && operation.units) || (newValue > oldValue ? newValue - oldValue : oldValue - newValue),
      type: operation.type || (newValue > oldValue ? 'add' : 'substract')
    }
    let currentShoppingCart = await api.addToShoppingCart(updatedOperation)
    dispatch('updateShoppingCart', currentShoppingCart)
  },
  updateShoppingCart({ commit }, shoppingCart) {
    if (shoppingCart) {
      shoppingCart = shoppingCart.filter(item => !!item.units)
      commit('mutateShoppingCart', shoppingCart)
    }
  },
  async clearShoppingCart({ dispatch }, payload) {
    let updated = await api.clearShoppingCart(payload)

    dispatch('updateShoppingCart', updated)
  },
  async clearShoppingCartAll({ dispatch }, payload) {
    let updated = await api.clearShoppingCartAll(payload)

    dispatch('updateShoppingCart', updated)
  },
  async checkoutDistributors({ dispatch }, distributorsList) {
    let result = await api.checkoutDistributors(distributorsList)

    dispatch('updateShoppingCart', result.shopping_cart)
    await dispatch('getPurchaseOrders')

    return result.purchase_orders[0]
  },
  async prefillDistributor({ dispatch }, payload) {
    let result = await api.prefillDistributor(payload)
    dispatch('updateShoppingCart', result)
  },
  async getPurchaseOrders({ commit }, filter) {
    let orders = await api.getPurchaseOrders(filter)
    orders.forEach(order => {
      if (order && (order.date || order.started_at)) {
        order.date = new Date(order.date || order.started_at)
        order.started_at = order.date
      }
    })
    orders.sort((a, b) => compareNumbers(b.date.getTime(), a.date.getTime()))
    commit('mutateOrders', orders)
  },
  async setPurchaseOrder({ commit }, payload) {
    let order = await api.updatePurchaseOrder(payload.id, payload.operation)
    if (order && (order.date || order.started_at)) {
      order.date = new Date(order.date || order.started_at)
      order.started_at = order.date
    }
    commit('mutateOrder', order)
    return order
  },
  async intakeFromOrder({ commit }, payload) {
    let result = await api.intakeFromOrder(payload.orderId, payload.data)

    if (result.purchase_order && result.purchase_order.date) {
      result.purchase_order.date = new Date(result.purchase_order.date)
    }

    commit('mutateOrder', result.purchase_order)

    if (result.movement && result.movement.date) {
      result.movement.date = new Date(result.movement.date)
    }
    commit('mutateMovement', result.movement)
    return result.movement
  },
  async setSubrecipe(z, payload) {
    let updatedSubrecipe = await api.updateSubrecipe(payload.id, payload.operation)

    return updatedSubrecipe
  },
  async getPOSItemsExtraData({ commit }, params) {
    let extraData = await api.getPOSItemsExtraData(params)

    commit('mutatePOSItemsExtraData', extraData)

    return extraData
  },
  async setPOSItem({ commit }, payload) {
    let updatedPOSItem = await api.updatePOSItem(payload.id, payload.operation)

    commit('mutatePOSItem', updatedPOSItem)

    return updatedPOSItem
  },
  async updateServingSize(z, payload) {
    let updatedServingSize = await api.updateServingSize(payload.id, payload.operation)
    updatedServingSize.type = 'predefined'
    //  commit('mutateServingSize', updatedServingSize)
    return updatedServingSize
  },
  async setServingSizesOrder(z, payload) {
    let updatedServingSizes = await api.setServingSizesOrder(payload)
    return updatedServingSizes
  },
  setItemToState({ commit }, payload) {
    commit('mutateBottle', payload)
  },
  async importItem({ commit }, payload) {
    let item = await api.bottleImport(payload)

    if (item) {
      commit('mutateBottle', item)

      if (item.item_distributors && item.item_distributors[0]) {
        commit('mutateItemVariant', item.item_distributors[0])
      }
    }
    return item
  },
  async updateBottle({ commit }, payload) {
    let updatedBottle = await api.updateBottle(payload.id, payload.operation)

    if (updatedBottle && !payload.id) {
      commit('mutateBottle', updatedBottle)

      if (updatedBottle.item_distributors && updatedBottle.item_distributors[0]) {
        commit('mutateItemVariant', updatedBottle.item_distributors[0])
      }
    }
    return updatedBottle
  },
  async setItemVariant(z, payload) {
    let updated = await api.saveItemVariant(payload.id, payload.operation)

    return updated
  },
  async setLocation(z, payload) {
    let updatedLocation = await api.updateLocation(payload.id, payload.operation)
    return updatedLocation
  },
  async importBottle(z, payload) {
    let updatedBottle = await api.bottleImport(payload)
    //commit('mutateBottle', updatedBottle)
    return updatedBottle
  },
  async setDistributor({ commit }, payload) {
    let distributor = await api.updateDistributor(payload.id, payload.operation)

    commit('mutateDistributor', distributor)
    return distributor
  },
  async setCategory(z, payload) {
    let response = await api.updateCategory(payload.id, payload.operation)

    return response
  },
  async setFamily(z, payload) {
    let response = await api.updateFamily(payload.id, payload.operation)

    return response
  },
  toggleUIEditMode({ commit, state }) {
    commit('mutateUIEditMode', !state.uiEditModeActive)
  },
  getWiskModalNextLayer({ commit, state }) {
    return new Promise(resolve => {
      let topLayer = { id: guid(), zIndex: 1100 },
        wiskModalLayers = merge([], state.wiskModalLayers)

      modalCounter++

      if (!(modalCounter % 25)) {
        topLayer.smile = true
      }
      if (wiskModalLayers.length) {
        topLayer.zIndex = wiskModalLayers[wiskModalLayers.length - 1].zIndex + 10
      }
      topLayer.count = wiskModalLayers.length
      wiskModalLayers.push(topLayer)
      commit('mutateWiskModalTopLayer', wiskModalLayers)
      resolve(topLayer)
    })
  },
  releaseWiskModalLayer({ commit, state }) {
    let wiskModalLayers = merge([], state.wiskModalLayers)

    if (wiskModalLayers.length) {
      wiskModalLayers.splice(wiskModalLayers.length - 1, 1)
    }
    commit('mutateWiskModalTopLayer', wiskModalLayers)
  },
  async subscribeToRealtimeSync({ commit, state, dispatch }) {
    await initDB({ commit, state, dispatch })
  },
  setGlobalAction({ commit, getters, dispatch, state }, payload) {
    let copy = merge({}, payload)

    const permissionMap = {
      inviteUsers: 'user_add',
      independentInventoryEdit: 'independent_inventory_view',
      families: 'family_category_view',
      familyEdit: 'family_category_view',
      categoryEdit: 'family_category_view',
      movementEdit: 'invoice_manage',
      glAccountEdit: 'gl_account_manage',
      taxEdit: 'tax_rate_manage',
      itemEdit: 'item_view',
      planSelectorEdit: 'billing_manage',
      parentVenueItems: 'shared_item_manage',
      venueTransferEdit: 'transfer_request_manage',
      venueEdit: 'venue_settings_manage'
    }
    if (copy && copy.action) {
      copy.action.key = guid()
    }
    if (copy && copy.caller) {
      console.log('+-+-+-+-+-+-+-+-+-+-+-+-+ setGlobalAction caller', copy.caller, copy)
    }

    if (!copy.skipPermissionCheck && (isEmpty(state.currentPermissionsByType) || !getters.configDataLoaded)) {
      setTimeout(() => {
        //permissions not here yet, come back a bit later
        dispatch('setGlobalAction', copy)
      }, 1000)
    } else if (!permissionMap[copy.type] || (permissionMap[copy.type] && state.currentPermissionsByType[permissionMap[copy.type]])) {
      if (copy.resetBeforeOpen) {
        commit('mutateGlobalAction', { type: copy.type, action: null })

        setTimeout(() => {
          commit('mutateGlobalAction', copy)
        }, 0)
      } else {
        commit('mutateGlobalAction', copy)
      }
    }
  },
  handleMultiChanges({ commit, state }, { type, dataMapper, transformer }) {
    if (debug) {
      console.time(`Performance investigation * handleMultiChanges before commit ${type}`)
      console.log(`Performance investigation * dataMapper for ${type}`, dataMapper)
    }
    let stateData
    if (dataMapper.stateAsCollector) {
      stateData = Object.keys(state[dataMapper.state]).reduce((acc, key) => acc.concat(state[dataMapper.state][key]), [])
    } else if (Array.isArray(state[dataMapper.state])) {
      stateData = [...state[dataMapper.state]]
      // handle merging draft pos items into pos items statically
      if (dataMapper.type === 'pos_item') {
        stateData.push(...state.draftPOSItems)
      }
    } else {
      stateData = Object.values(state[dataMapper.state])
    }
    if (debug) {
      console.log(`Performance investigation * current stateData for ${type}`, [...stateData])
    }
    dataMapper.deletedCollector.forEach(item => {
      const index = stateData.findIndex(i => (i && typeof i === 'object' ? i[dataMapper.primaryKey || 'id'] : i) === item)
      if (index > -1) {
        stateData.splice(index, 1)
      }
    })
    dataMapper.collector.forEach(item => {
      const index = stateData.findIndex(i => (i && typeof i === 'object' ? i[dataMapper.primaryKey || 'id'] : i) === item.data[dataMapper.primaryKey || 'id'])
      if (index === -1) {
        stateData.push(transformer ? transformer(item.data) : item.data)
      } else {
        stateData[index] = { ...(transformer ? transformer(item.data) : item.data) }
      }
    })
    if (debug) {
      console.log(`Performance investigation * new stateData for ${type}, before commit`, [...stateData])
    }
    commit(dataMapper.multi, stateData)
    if (dataMapper.afterCommit) {
      dataMapper.afterCommit(dataMapper.collector)
    }
    if (debug) {
      console.timeEnd(`Performance investigation * handleMultiChanges before commit ${type}`)
    }
  }
}
