<template>
  <div v-if="params.node.group && !params.showInGroup && !visibleInGroupComputed" class="wisk-cell-group d-flex">
    <div v-if="params.wiskGroupDisplayCell" class="wisk-group-title d-flex me-5" :title="getTitleFrom(params.value) + ' ' + childrenCount">

      <v-menu :triggers="['click']" placement="bottom-start" :distance="-6" popperClass="wisk-group-toggle-wrapper">
        <b-button variant="link" class="px-1 py-0 me-1 in-grid-cell-visible-on-hover" @dblclick.stop="fillAvailableGroupStates" @click.stop="fillAvailableGroupStates">
          <icon class="" :scale="1" name="wisk-more-info" />
        </b-button>

        <template #popper>
          <div role="menu" class="dropdown-menu" style="position: static; display:block; width: 160px;">
            <b-dropdown-item v-for="c in groupStateActions" :key="c.id" @click="changeGroupState(c.id)">
              {{ c.title }}
            </b-dropdown-item>
          </div>
        </template>
      </v-menu>

      {{ getTitleFrom(params?.node?.key || params.value) }}
      {{ childrenCount }}
    </div>
    <div class="wisk-group-actions d-flex" v-if="mounted && id" :class="[colIdClass, { 'no-hide': forceActionsVisibility }]" @click.stop>

      <div v-for="(action, index) in actions" :key="index" class="me-2">
        <popoverDropdown v-if="action && action.children && (!action.available || (action.available && action.available(id, params)))"
          :items="translateActionsToPopoverItems(action.children)" buttonClass="wisk-group-action menu-button" @visible="forceActionsVisibility = $event"
          :title="action.getTitle ? action.getTitle(id, params) : action.title || ''" :variant="action.variant" :placement="action.dropdownPlacement" :containerWidth="action.containerWidth" />

        <b-button class="wisk-group-action" :variant="action.variant" size="sm" :class="formatAsClassName(action)"
          @click.stop="action.action(id, params.node)" v-if="action && !action.children && (!action.available || (action.available && action.available(id, params)))"
          :disabled="(action.disabled && action.disabled(id, params))" :title="(action.disabled && action.disabled(id)) ? action.disabledInfo : action.info || ''">
          <!-- action.disabled would be better to be named getDisabled since it is a function-->
          {{ action.getTitle ? action.getTitle(id) : action.title || '' }}

        </b-button>
      </div>
    </div>
  </div>

  <div v-else class="cell-text">
    <textInput v-if="!cellOverlayVisible && !asDatePicker" :className="validationClass + ' form-control ' + inputClass" ref="textInput" @blur="onBlur" :readonly="readonly" :placeholder="placeholder"
      :prefix="prefix" :suffix="suffix" selectOnFocus :multiline="multiline" />

    <datePickerInput v-if="!cellOverlayVisible && asDatePicker" :className="validationClass + ' form-control ' + inputClass" ref="textInput" @input="onDatePickerInput" :readonly="readonly" :placeholder="placeholder"
      startWithModalOpen @modalClose="resetStyle" />

    <div @mousedown="onCellClick" v-show="cellOverlayVisible" class="overlay form-control plain-text-span plain-text" :class="colIdClass" :title="formattedValue">
      {{ formattedValue }}
    </div>

    <v-menu :triggers="['hover', 'focus']" :distance="5" v-if="!valid && mounted">
      <icon class="info-icon text-danger no-hide validation" name="wisk-warning" />

      <template v-slot:popper>
        <div class="p-2">

          <span class="text-danger text-bold">
            {{ validationMessage }}
          </span>
          <p v-show="!allowBlur"></p>
          <p v-show="!allowBlur">
            {{ translate('txtValidationEscapeToReset') }}
          </p>
        </div>
      </template>
    </v-menu>

    <slot></slot>
  </div>
</template>

<script>
import get from 'lodash.get'
import isObject from 'lodash.isobject'
import isFinite from 'lodash.isfinite'
import { guid, fromJSON, formatDate } from '@/modules/utils'
import textInput from '@/components/common/TextInput'
import datePickerInput from '@/components/common/DatePickerInput'
import popoverDropdown from '@/components/common/PopoverDropdown'

export default {
  name: 'CellTextBase',
  emits: ['editing'],
  components: { textInput, popoverDropdown, datePickerInput },
  props: {
    params: Object,
    suffix: { type: String, default: '' }
  },
  data() {
    return {
      oldValue: null,
      localValue: null,
      childrenCount: '',
      agCell: null,
      inputClassBase: 'plain-text',
      cellOverlayVisible: true,
      valid: true,
      allowBlur: false,
      forceActionsVisibility: false,
      validationMessage: '',
      mounted: false,
      groupStateActions: [
        { id: '1', title: '111' },
        { id: '2', title: '222' }
      ]
    }
  },
  computed: {
    domId() {
      return 'z' + guid()
    },
    visibleInGroupComputed() {
      let siblingsVisibleInGroup = get(this.params, 'node.rowGroupColumn.colDef.cellRendererParams.siblingsVisibleInGroup', [])
      return siblingsVisibleInGroup.includes(this.params.colDef.colId)
    },
    translate() {
      return (this.params && this.params.translate) || (() => '')
    },
    asDatePicker() {
      return this.params.asDatePicker
    },
    computedValue() {
      if (typeof this.params.value === 'string' && !this.params.value.startsWith('{')) {
        return this.params.value
      }
      if (this.asDatePicker && typeof this.params.value !== 'string' && this.params.value.input_value) {
        return new Date(this.params.value.input_value)
      }
      if (this.params.value.input_value && typeof this.params.value.input_value.title === 'string') {
        return this.params.value.input_value.title
      }

      return this.params.value.input_value
    },
    formattedValue() {
      if (this.params.node.rowPinned) {
        return this.params?.data[this.params?.colDef?.colId] || ''
      }

      if (this.asDatePicker) {
        return formatDate(this.localValue)
      }
      return this.localValue ? this.prefix + this.localValue + this.suffix : ''
    },
    validationClass() {
      return this.valid ? '' : 'is-invalid'
    },
    placeholder() {
      return this.params.placeholder
    },
    multiline() {
      return this.params.multiline
    },
    validations() {
      return this.params.validations || []
    },
    type() {
      return this.params.type
    },
    id() {
      let data = fromJSON(this.params.value)
      return (data && data.id) || (this.params.value && this.params.value.id) || 0
    },
    prefix() {
      return this.params.prefix || ''
    },
    readonly() {
      return !!this.params.readonly || !!this.params.value.readonly
    },
    colIdClass() {
      return 'col-id-' + this.params.colDef.colId
    },
    inputClass() {
      return this.inputClassBase + ' wisk-cell-text ' + this.params.colDef.colId
    },
    actions() {
      if (Array.isArray(this.params.wiskGroupActions)) {
        return this.params.wiskGroupActions
      }
      return []
    }
  },
  methods: {
    computeChildrenCount() {
      let count = parseInt(get(this.params, 'node.allChildrenCount'), 10),
        subtract = 0

      if (this.params.getCustomAllChildrenCount) {
        count = this.params.getCustomAllChildrenCount(this.params)
      }
      if (isFinite(count)) {
        if (this.params.getSubtractFromAllChildrenCount) {
          subtract = this.params.getSubtractFromAllChildrenCount(this.params)
        } else if (this.params.subtractFromAllChildrenCount) {
          subtract = this.params.subtractFromAllChildrenCount
        }
        count -= subtract
      }
      if (!count) {
        count = 0
      }

      this.childrenCount = '(' + count + ')'

    },
    getRootNodeRecursive(node) {
      if (node?.parent) {
        return this.getRootNodeRecursive(node.parent)
      }
      return node
    },
    fillAvailableGroupStates() {
      this.groupStateActions = []

      let rootNode = this.getRootNodeRecursive(this.params.node),
        thisNodeState = this.getNodesGroupExpandedStateRecursive(this.params.node),
        allNodesState = this.getNodesGroupExpandedStateRecursive(rootNode), //let's see for how long this will work
        thisNodeExpanded = thisNodeState[0][0],
        groupStateActions = []

      groupStateActions.push(
        thisNodeExpanded ? { id: 'collapse', title: this.translate('txtWiskGridGroupCollapse') } : { id: 'expand', title: this.translate('txtWiskGridGroupExpand') }
      )

      thisNodeState[1] = [...new Set(thisNodeState[1])]
      allNodesState[1] = [...new Set(allNodesState[1])]

      thisNodeState[1].forEach(expanded => {
        if (expanded) {
          groupStateActions.push({ id: 'collapseChildren', title: this.translate('txtWiskGridGroupCollapseChildren') })
        } else {
          groupStateActions.push({ id: 'expandChildren', title: this.translate('txtWiskGridGroupExpandChildren') })
        }
      })

      allNodesState[1].forEach(expanded => {
        if (expanded) {
        groupStateActions.push({ id: 'collapseAll', title: this.translate('txtWiskGridGroupCollapseAll') })
        } else {
        groupStateActions.push({ id: 'expandAll', title: this.translate('txtWiskGridGroupExpandAll') })
        }
      })
      this.groupStateActions = groupStateActions
    },
    toggleExpanded(node, expanded, recursive, skipSelf) {
      if (node.group) {
        let children = node.allLeafChildren,
          hidden = false
        if (children && children.length === 1) {
          hidden = children[0].data.wiskRowHidden
        }
        if (hidden) {
          node.setExpanded(false)
        }
        if (!hidden) {
          if (!skipSelf) {
            node.setExpanded(expanded)
          }
          if (recursive) {
            node.childrenAfterGroup.forEach(child => {
              this.toggleExpanded(child, expanded, recursive)
            })
          }
        }
      }
    },
    changeGroupState(type) {
      switch (type) {
        case 'collapse':
          this.toggleExpanded(this.params.node, false)

          break
        case 'expand':
          this.toggleExpanded(this.params.node, true)

          break
        case 'collapseChildren':
          this.toggleExpanded(this.params.node, false, true, true)
          break
        case 'expandChildren':
          this.toggleExpanded(this.params.node, true, true /*, true*/)
          break
        case 'collapseAll':
          this.params.api.collapseAll()
          break
        case 'expandAll':
          this.params.api.expandAll()
          break

        default:
          break
      }
      setTimeout(() => {
        this.params.api.forEachNodeAfterFilterAndSort(node => {
          let children = node.allLeafChildren
          if (children && children.length === 1) {
            let hidden = children[0].data.wiskRowHidden
            if (hidden) {
              node.setExpanded(false)
            }
          }
        })

        if (this.$refs.groupStatePopover && this.$refs.groupStatePopover.doClose) {
          this.$refs.groupStatePopover.doClose()
        }
      }, 0)
    },
    getNodesGroupExpandedStateRecursive(node, agg = [], index = 0) {
      if (node && node.group) {
        agg[index] = agg[index] || []
        agg[index].push(node.expanded)

        if (node.childrenAfterGroup && node.childrenAfterGroup.length) {
          index += 1
          node.childrenAfterGroup.forEach(inner => {
            this.getNodesGroupExpandedStateRecursive(inner, agg, index)
          })
        }
      }

      return agg
    },
    getTitleFrom(payload) {
      let data = fromJSON(payload),
        noValueText = `(${this.translate('txtGenericNoValue')})`,
        text = data?.title || payload?.input_value?.title || payload?.title || payload?.input_value || ((isObject(data) || isObject(payload)) ? noValueText : payload)

      return text
    },
    translateActionsToPopoverItems(actions) {
      return actions
        .filter(a => a)
        .map(action => ({
          ...action,
          action: () => {
            action.action(this.id, this.params.node)
          },
          onClick: () => {
            action.action(this.id, this.params.node)
          },
          label: action.title,
          getDisabled: action.disabled,
          checkVisibility: () => {
            if (action.checkVisibility) {
              return action.checkVisibility(this.id, this.params.node)
            }
            return true
          },
          children: (action.children || []).map(c => ({
            ...c,
            action: () => {
              c.action(this.id, this.params.node)
            },
            onClick: () => {
              c.action(this.id, this.params.node)
            },
            label: c.title,
            getDisabled: c.disabled,
            checkVisibility: () => {
              if (c.checkVisibility) {
                return c.checkVisibility(this.id, this.params.node)
              }
              return true
            }
          }))
        }))
    },
    formatAsClassName(action) {
      let title = action.title || ''
      if (action.getTitle) {
        title = action.getTitle(this.id)
      }
      return title.toLowerCase().replace(/ /g, '-')
    },
    onCellClick() {
      if (
        this.readonly &&
        this.params &&
        this.params.api &&
        this.params.api.gridOptionsWrapper &&
        this.params.api.gridOptionsWrapper.gridOptions &&
        this.params.api.gridOptionsWrapper.gridOptions.rowSelection === 'single'
      ) {
        this.params.node.setSelected(true)
      }
      if (!this.readonly && !this.params.node.group) {
        let startEdit = () => {
          this.cellOverlayVisible = false
          this.initValue()

          if (this.agCell) {
            this.agCell.classList && this.agCell.classList.remove && this.agCell.classList.remove('ag-cell-not-inline-editing')
            this.agCell.classList && this.agCell.classList.add && this.agCell.classList.add('ag-cell-inline-editing')
          }
          this.inputClassBase = ''
          setTimeout(() => {
            this.$refs.textInput && this.$refs.textInput.focus && this.$refs.textInput.focus()
          }, 0)
        }

        if (this.params?.confirmBeforeEdit) {
          this.params?.confirmBeforeEdit.confirm().then(startEdit)
        } else {
          startEdit()
        }
      }
    },
    initValue() {
      setTimeout(() => {
        if (this.$refs.textInput && this.$refs.textInput.setValue) {
          this.$refs.textInput.setValue(this.computedValue)
          this.oldValue = this.computedValue
        }
      }, 0)
    },
    resetValue() {
      if (this.$refs.textInput && this.$refs.textInput.setValue) {
        this.$refs.textInput.setValue(this.oldValue)
      }
    },
    resetStyle() {
      this.inputClassBase = 'plain-text'
      this.cellOverlayVisible = true

      if (this.agCell) {
        this.agCell.classList.remove('ag-cell-inline-editing')
        this.agCell.classList.add('ag-cell-not-inline-editing')
      }
    },
    onDatePickerInput(value) {
      let checkOld = this.oldValue || { getTime: () => NaN },
        checkNew = value || { getTime: () => NaN }
      checkOld.getTime = checkOld.getTime || (() => NaN)
      checkNew.getTime = checkNew.getTime || (() => NaN)
      let hasChange = checkOld.getTime() !== checkNew.getTime()

      if (hasChange && typeof this.params.save === 'function') {
        this.params.save(value, this.id, this.type, this.params.value)
        this.localValue = value
      }
      if (hasChange && typeof this.params.updateValue === 'function') {
        this.params.updateValue({ value, id: this.id, type: this.type, data: this.params.value, previousValue: this.oldValue })
        this.localValue = value
      }
      this.resetStyle()
    },
    onBlur(event) {
      let ok = true,
        value = event.value

      if (event.stop) {
        this.resetValue()
        this.resetStyle()
      } else {
        for (let i = 0; i < this.validations.length; i++) {
          const validation = this.validations[i]
          ok = validation.validator(value, this.computedValue, this.params.data)
          if (!ok) {
            i = this.validations.length
            this.validationMessage = validation.message
            this.allowBlur = validation.allowBlur
            if (!validation.allowBlur) {
              this.$refs.textInput && this.$refs.textInput.focus()
            }
          }
        }

        if (ok) {
          if ((this.oldValue || '') !== value && typeof this.params.save === 'function') {
            this.params.save(value, this.id, this.type, this.params.value)
            this.localValue = value
          }

          if ((this.oldValue || '') !== value && typeof this.params.updateValue === 'function') {
            this.params.updateValue({ value, id: this.id, type: this.type, data: this.params.value, previousValue: this.oldValue })
            this.localValue = value
          }
          this.resetStyle()
        }
      }
      this.valid = ok
    }
  },
  mounted() {
    this.agCell = this.params.eGridCell

    if (get(this.params, 'node.group')) {
      this.params.api && this.params.api.addEventListener('modelUpdated', this.computeChildrenCount)
      this.computeChildrenCount()
    }

    this.mounted = true
  },
  beforeUnmount() {
    this.params.api && this.params.api.removeEventListener('modelUpdated', this.computeChildrenCount)
  },
  watch: {
    computedValue: {
      handler() {
        this.oldValue = this.computedValue
        this.localValue = this.computedValue || ''
      },
      immediate: true
    },
    cellOverlayVisible(cellOverlayVisible) {
      setTimeout(() => {
        this.$emit('editing', !cellOverlayVisible)
      }, 0)
    }
  }
}
</script>

<style lang="scss">
.ag-cell-wrapper>span {
  display: flex;
  height: 100% !important;
  align-items: center;
}

.text-end .ag-cell-wrapper>span {
  justify-content: right;
}

.text-start .ag-cell-wrapper>span {
  justify-content: left;
}

.cell-text {
  width: 100%;
  height: 100%;

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:hover {
      background-color: rgba(#63c2de, 0.1);
    }
  }
}

.ag-theme-balham .ag-cell-inline-editing {
  height: inherit;
}

.wisk-group-actions-wrapper {
  z-index: 10;
}

.wisk-group-actions {
  .wisk-group-action.btn-sm {
    padding-top: 1px;
    padding-bottom: 1px;
    font-size: 0.65rem;
  }
}

.wisk-group-toggle-wrapper {
  .v-popper__arrow-container {
    display: none;
  }
}
</style>
