import get from 'lodash.get'
import agGridFilters from '@/modules/agGridFilters'
import { formatDate, currencyFormat, currencyFormatHideZero, toFixed, getLeafNodesRecursive, percentageFormat, setupCustomFieldsAsColumns, getPOSItemIngredientDescription } from '@/modules/utils'
import cellRenderers from '@/components/cells/cellRenderers'

const getGridColumns = ({
  translations,
  save,
  openEditPOSItem,
  setGlobalAction,
  hasPosSaleCategory,
  hasPosMenuCategory,
  customFields,
  allergens,
  currentPermissionsByType,
  importIngredientsFromPOSItems,
  cloneAsDraftPOSItem,
  disableSelection
}) => {
  let translate = translations.translate,
    columns = [
      {
        colId: 'dropdownMenu',
        sortOrder: 100,
        cellRenderer: 'CellMenu',
        headerName: '',
        headerClass: ['ps-2', 'pt-1'],
        maxWidth: 25,
        width: 25,
        minWidth: 25,
        remove: !currentPermissionsByType.pos_item_manage,
        cellClass: ['menu-cell'],
        cellRendererParams: { excludeFromExport: true },
        sortable: false,
        suppressColumnsToolPanel: true,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        valueGetter: params => ({
          group: params.node.group,
          items: [
            {
              label: translations.txtPOSItemEdit,
              onClick: openEditPOSItem,
              value: params.data && params.data.id
            },
            {
              label: translations.txtGenericArchive,
              value: [params.data && params.data.id],
              onClick: () => {
                save({ value: true, id: params.data.id, type: 'archive' })
              },
              hide: params.data && params.data.archived
            },
            {
              label: translations.txtGenericRestore,
              value: [params.data && params.data.id],
              onClick: () => {
                save({ value: false, id: params.data.id, type: 'archive' })
              },
              hide: !params.data || !params.data.archived
            },
            {
              label: translations.txtPOSItemsImportIngredientsFrom,
              value: [],
              onClick: () => importIngredientsFromPOSItems([{ id: params.data && params.data.id }]),
              allowWordBreak: true
            },
            {
              label: translations.txtPOSItemCloneAsDraft,
              value: [],
              onClick: () => cloneAsDraftPOSItem(params.data && params.data.id)
            }
          ]
        })
      },
      {
        headerTooltip: translations.txtGenericSelectAll,
        headerClass: ['checkbox-cell'],
        headerWidth: 0,
        minWidth: 20,
        width: 20,
        maxWidth: 20,
        sortOrder: 200,
        remove: !currentPermissionsByType.pos_item_manage || disableSelection,
        colId: 'checkboxSelection',
        cellClass: ['checkbox-cell'],
        checkboxSelection: params => !params.node.group && params.data && !params.data.wiskRowHidden,
        sortable: false,
        suppressColumnsToolPanel: true,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        suppressMovable: true,
        cellRendererParams: { excludeFromExport: true },
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      {
        colId: 'image',
        sortOrder: 300,
        headerName: translations.txtGenericImage,
        headerClass: ['hide-header-label'],
        field: 'image',
        cellRenderer: 'CellImage',
        maxWidth: 50,
        hide: true,
        width: 50,
        minWidth: 50,
        sortable: false,
        cellRendererParams: {
          excludeFromExport: true,
          overlayIcon: 'wisk-edit',
          filterDisplay: 'tinyThumb',
          onClick: data => {
            if (!data.wiskRowHidden) {
              setGlobalAction({
                type: 'fileUpload',
                action: {
                  fileTypesAccept: 'image/*',
                  useGoogle: true,
                  target: data,
                  save: value => {
                    save({ value, id: data.id, type: 'image', previousValue: data.image })
                  }
                }
              })
            }
          }
        },
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        cellClass: ['image-cell'],
        valueGetter: params => ({
          image: (params.data && params.data.image) || '3d5df52e-18ea-4d36-9d26-7b3059f73a5f.png'
        })
      },
      {
        colId: 'allergens',
        sortOrder: 1020,
        headerName: translations.txtAllergens,
        cellRenderer: cellRenderers.CellPopMultiselect,
        valueSetter: params => {
          params.data.allergens = params.newValue

          return true
        },
        editable: false,
        minWidth: 100,
        remove: !allergens,
        width: 100,
        hide: false,
        cellClass: ['text-center', 'stringTypeCellStyle'],
        keyCreator: params => get(params, 'value.input_value', []).join(', '),
        cellEditorParams: {
          autoOpen: true,
          translate,
          multiple: true,
          readonly: true,
          useValueFormatter: true,
          allowAddNewItem: false,
          multiselectWidth: 300,
          multiselectOptions: { trackBy: 'id' },
          getItems: () => allergens
        },
        valueFormatter: params => params.value.input_value.map(id => (allergens.find(a => a.id === id) || { title: 'Not found' }).title).join(', '),
        valueGetter: params => ({
          id: get(params, 'data.id'),
          input_value: (params.data && params.data.allergens) || []
        })
      },
      {
        headerName: translations.txtGenericPOSCode,
        cellRenderer: 'CellText',
        colId: 'pluNumber',
        sortOrder: 300,
        minWidth: 80,
        width: 120,
        cellClass: ['text-center', 'stringTypeCellStyle'],
        maxWidth: 300,
        cellRendererParams: {
          translate: translations.translate,
          updateValue: save,
          type: 'plu_number'
        },
        valueGetter: params => ({
          id: params.data && params.data.id,
          readonly: params.data && !params.data.editable,
          input_value: params.data && params.data.plu_number
        })
      },
      {
        headerName: translations.txtGenericTitle,
        colId: 'title',
        minWidth: 50,
        width: 150,
        sortOrder: 400,
        maxWidth: 300,
        sort: 'asc',
        valueGetter: params => {
          let title = params.data?.title
          if (params.data?.is_modifier) {
            title += ` (${translations.txtPosItemsGridModifier})`
          }
          return title
        }
      },
      {
        headerName: translations.txtGenericIngredients,
        colId: 'ingredients',
        minWidth: 150,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        maxWidth: 500,
        cellRenderer: 'CellIngredients',
        sortOrder: 500,
        sortable: false,
        cellRendererParams: {
          useValueFormatter: true,
          readonly: true,
          onClick: openEditPOSItem,
          translate: translations.translate
        },
        valueFormatter: params => params.value && params.value.ingredients && params.value.ingredients.map(i => getPOSItemIngredientDescription(i)).join(', \n'),
        valueGetter: params => ({
          id: params && params.data && params.data.id,
          ingredients: params && params.data && params.data.ingredients
        })
      },
      {
        headerName: translations.columnMenuPrice,
        colId: 'menuPrice',
        field: 'price',
        minWidth: 70,
        sortOrder: 600,
        width: 100,
        cellClass: ['text-end', 'pe-2', 'currency'],
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => currencyFormat(params.value),
        valueGetter: params => (params.data && params.data.price) || 0
      },
      {
        headerName: translations.txtGenericCost,
        field: 'cost',
        colId: 'cost',
        minWidth: 70,
        sortOrder: 700,
        width: 100,
        cellClass: ['text-end', 'pe-2', 'currency'],
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => (params.data && params.data.cost) || 0
      },
      {
        headerName: translations.txtGenericProfit,
        colId: 'profit',
        minWidth: 70,
        sortOrder: 700,
        width: 100,
        cellClass: ['text-end', 'pe-2', 'currency'],
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => params?.data?.stats?.cost?.profit || 0
      },
      {
        headerName: translations.txtPOSItemsCostAlert,
        colId: 'costAlert',
        minWidth: 70,
        sortOrder: 750,
        width: 100,
        cellClass: ['text-end', 'pe-2'],
        cellRendererParams: { useValueFormatter: true },
        valueFormatter: params => (params.value ? percentageFormat(params.value, 2, true, '') : ''),
        valueGetter: params => get(params, 'data.target_cost')
      },
      {
        headerName: translations.txtGenericPercentage,
        colId: 'percentage',
        cellClass: params => {
          let classList = ['cellStyle', 'grid-text-span', 'text-center']
          if (params.value > 1) {
            classList.push('percentage-warning')
          }
          return classList
        },
        minWidth: 50,
        width: 80,
        sortOrder: 800,
        cellRendererParams: { useValueFormatter: true },
        valueFormatter: params => percentageFormat(params.value, 2, true, ''),
        valueGetter: params => get(params, 'data.stats.cost.percentage')
      },
      {
        headerName: translations.txtGenericFamily,
        colId: 'family',
        hide: true,
        cellClass: ['grid-text-span', 'text-center'],
        sortOrder: 900,
        width: 100,
        minWidth: 80,
        sort: 'asc',
        columnGroupShow: 'open',
        enableRowGroup: true,
        valueGetter: params => {
          let posItemCategory = params?.data?.category || {},
            value = posItemCategory.family || posItemCategory.primary_family || translations.txtGenericUnknown
          return value
        }
      },
      {
        headerName: translations.txtGenericCategory,
        colId: 'category',
        hide: true,
        cellClass: ['grid-text-span', 'text-center'],
        sortOrder: 1000,
        width: 100,
        minWidth: 80,
        sort: 'asc',
        columnGroupShow: 'open',
        enableRowGroup: true,
        valueGetter: params => {
          let posItemCategory = get(params, 'data.category', {}),
            value = posItemCategory.category || posItemCategory.primary_category || translations.txtGenericUnmapped

          return value
        }
      },
      {
        headerName: translations.txtPosItemsGridPosCategoryMenu,
        colId: 'posCategoryMenu',
        hide: true,
        cellClass: ['grid-text-span'],
        sortOrder: 1100,
        width: 100,
        remove: !hasPosMenuCategory,
        minWidth: 80,
        sort: 'asc',
        columnGroupShow: 'open',
        enableRowGroup: true,
        valueGetter: params => get(params, 'data.pos_menu_category.title', translations.txtGenericUnmapped)
      },
      {
        headerName: translations.txtPosItemsGridPosCategorySale,
        colId: 'posCategorySale',
        hide: true,
        cellClass: ['grid-text-span'],
        sortOrder: 1200,
        width: 100,
        remove: !hasPosSaleCategory,
        minWidth: 80,
        sort: 'asc',
        columnGroupShow: 'open',
        keyCreator: params => params.value || translations.txtGenericGroupTitleMissing,
        enableRowGroup: true,
        valueGetter: params => get(params, 'data.pos_sale_category.title')
      },
      {
        headerName: translations.txtPOSItemsLast30Profit,
        cellClass: ['grid-text-span', 'text-center', 'currency'],
        width: 120,
        minWidth: 120,
        colId: 'posGridGroupLast30Profit',
        sortOrder: 1310,
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => (params.data && params.data.last_month_sales ? params.data.last_month_sales.profit : 0)
      },
      {
        headerName: translations.txtPOSItemsLast30QuantitSold,
        cellClass: ['grid-text-span', 'text-center'],
        width: 80,
        colId: 'posGridGroupLast30QuantitySold',
        sortOrder: 1320,
        hide: true,
        minWidth: 50,
        columnGroupShow: 'open',
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => toFixed(params.value, 0),
        valueGetter: params => (params.data && params.data.last_month_sales ? params.data.last_month_sales.quantity : 0)
      },
      {
        headerName: translations.txtPOSItemsLast30SalesCost,
        cellClass: ['grid-text-span', 'text-center', 'currency'],
        hide: true,
        width: 80,
        sortOrder: 1330,
        minWidth: 50,
        colId: 'posGridGroupLast30Cost',
        columnGroupShow: 'open',
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => params?.data?.last_month_sales?.cost || 0
      },
      {
        headerName: translations.txtPOSItemsLast30Total,
        cellClass: ['grid-text-span', 'text-center', 'currency'],
        width: 80,
        hide: true,
        minWidth: 50,
        colId: 'posGridGroupLast30Total',
        sortOrder: 1340,
        columnGroupShow: 'open',
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => (params.data && params.data.last_month_sales ? params.data.last_month_sales.total : 0)
      },
      {
        headerName: translations.txtPosItemsGridLast30DaysActualCostPercentage,
        cellClass: ['grid-text-span', 'text-center'],
        width: 80,
        sortOrder: 1350,
        hide: true,
        minWidth: 50,
        columnGroupShow: 'open',
        colId: 'posGridGroupLast30ActualCostPercentage',
        cellRendererParams: { useValueFormatter: true },
        valueFormatter: params => percentageFormat(params.value),
        valueGetter: params => (params.data && params.data.last_month_sales ? params.data.last_month_sales.beverage_cost : 0)
      },
      {
        headerName: translations.txtPosItemsGridLast30DaysAveragePrice,
        cellClass: ['grid-text-span', 'text-center', 'currency'],
        width: 80,
        sortOrder: 1360,
        hide: true,
        minWidth: 50,
        columnGroupShow: 'open',
        colId: 'posGridGroupLast30AvgPrice',
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => (params.data && params.data.last_month_sales ? params.data.last_month_sales.average_price : 0)
      },
      {
        headerName: translations.txtPosItemsLastSoldDate,
        colId: 'lastSoldDate',
        ...agGridFilters.date,
        hide: true,
        sortOrder: 1500,
        valueFormatter: params => formatDate(params.value),
        valueGetter: params => get(params, 'data.last_sale_date')
      },
      {
        headerName: translations.txtGenericNotes,
        colId: 'notes',
        field: 'notes',
        cellClass: ['wrap-text'],
        ...agGridFilters.text,
        hide: true,
        sortOrder: 1600
      },
      {
        headerName: translations.txtPosItemsGridModifier,
        colId: 'modifier',
        width: 40,
        sortOrder: 1700,
        suppressSortingToolPanel: true,
        hide: true,
        ...agGridFilters.text,
        valueGetter: params => {
          if (params.node.group) {
            return ''
          }
          return params.data?.is_modifier ? translations.txtGenericYes : translations.txtGenericNo
        }
      },
      {
        colId: 'more',
        sortOrder: 27000,
        headerName: translations.columnDetails,
        minWidth: 100,
        width: 100,
        cellClass: ['cell-more-details'],
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        cellRenderer: 'CellMoreDetails',
        cellRendererParams: {
          excludeFromExport: true,
          onClick: openEditPOSItem
        },
        valueGetter: params => ({
          id: (params.data && params.data.id) || '',
          group: !!params.node.group
        })
      }
    ]

  let customFieldsColumns = setupCustomFieldsAsColumns({ customFields, translate, save, agGridFilters, columns })

  return [...columns, ...customFieldsColumns]
}

const getItemsGridColumns = ({ translations, servingSizes, openCustomServingSize, save, disabled, allergens }) => ({
  dropdownMenu: {
    forceOverrideValuegetter: true,
    remove: disabled,
    cellRendererParams: { excludeFromExport: true },
    valueGetter: params => ({
      group: params.node.group,
      items: [
        {
          onClick: item => {
            if (item) {
              save({
                type: 'ingredient_delete',
                value: {
                  ingredient: item.operation
                }
              })
            }
          },
          label: translations.txtGenericRemove,
          value: params.data
        }
      ]
    })
  },
  checkboxSelection: { hide: false },
  title: {
    hide: false,
    width: 400,
    sort: null
  },
  image: {
    cellRendererParams: { excludeFromExport: true },
    hide: false
  },
  servingSize: {
    cellRenderer: 'CellPopMultiselect',
    colId: 'servingSize',
    sortOrder: 700,
    width: 100,
    maxWidth: 160,
    headerName: translations.txtPOSItemsServingSize,
    cellRendererParams: {
      translate: translations.translate,
      updateValue: params => {
        let found = servingSizes.find(s => s && s.id === params.value)

        if (found.type === 'custom') {
          openCustomServingSize(params.id)
        } else {
          save({ ...params, value: { ingredient: params.data.data.operation, serving_size: { ...found } } })
        }
      },
      required: true,
      multiselectWidth: 280,
      readonly: disabled,
      type: 'ingredient_update',
      extraButton: {
        id: 1,
        getVisible: data => get(data, 'serving_size.type') === 'custom',
        action: id => {
          openCustomServingSize(id)
        },
        icon: 'wisk-edit'
      }
    },
    valueGetter: params => {
      let type = get(params, 'data.measurement.type', 'volume'),
        extraText = `${get(params, 'data.serving_size.measurement.quantity')} ${get(params, 'data.serving_size.measurement.unit_of_measurement')}`,
        items = servingSizes.filter(d => !d.archived && (d.measurement.type === type || d.measurement.type === 'unit'))

      return {
        id: params.data && params.data.id,
        input_value: get(params, 'data.serving_size.id') ? params.data.serving_size : servingSizes.find(s => s && s.type === 'custom'),
        items,
        data: get(params, 'data'),
        extraText
      }
    }
  },
  volumePercentage: {
    headerName: translations.txtGenericVolumePercentage,
    hide: true,
    cellClass: ['text-end', 'pe-2'],
    colId: 'volumePercentage',
    sortOrder: 1000,
    width: 120,
    cellRendererParams: { useValueFormatter: true },
    valueFormatter: params => percentageFormat(params.value),
    valueGetter: params => get(params, 'data.stats.volume.percentage', 0)
  },
  cost: {
    sortOrder: 1500,
    colId: 'cost',
    width: 100,
    headerName: translations.txtGenericCost,
    cellRendererParams: { decimals: 2 },
    cellClass: ['text-end', 'pe-2', 'currency'],
    valueFormatter: params => currencyFormatHideZero(params.value),
    valueGetter: params => get(params, 'data.stats.cost.dollars', 0)
  },
  percentage: {
    headerName: translations.txtGenericCostPercentage,
    cellClass: ['text-end', 'pe-2'],
    sortOrder: 1600,
    colId: 'percentage',
    cellRenderer: 'CellCostPercentage',
    width: 100,
    cellRendererParams: { useValueFormatter: true },
    valueFormatter: params => percentageFormat(params.value),
    valueGetter: params => get(params, 'data.stats.cost.percentage', 0)
  },
  allergens: {
    colId: 'allergens',
    sortOrder: 1700,
    hide: false,
    headerName: translations.txtAllergens,
    cellRenderer: cellRenderers.CellPopMultiselect,
    valueSetter: params => {
      params.data.allergens = params.newValue

      return true
    },
    editable: false,
    minWidth: 100,
    width: 100,
    remove: !allergens,
    cellClass: ['text-center', 'stringTypeCellStyle'],
    keyCreator: params => get(params, 'value.input_value', []).join(', '),
    cellEditorParams: {
      autoOpen: true,
      translate: translations.translate,
      multiple: true,
      readonly: true,
      useValueFormatter: true,
      allowAddNewItem: false,
      multiselectWidth: 300,
      multiselectOptions: { trackBy: 'id' },
      getItems: () => allergens
    },
    valueFormatter: params => params.value.input_value.map(id => (allergens.find(a => a.id === id) || { title: 'Not found' }).title).join(', '),
    valueGetter: params => ({
      id: get(params, 'data.item_id'),
      input_value: (params.data && params.data.allergens) || []
    })
  }
})

const getFlatGridColumns = ({ translations, save, openEditPOSItem, setGlobalAction, hasPosSaleCategory, hasPosMenuCategory, customFields, currentPermissionsByType, servingSizes }) => {
  let translate = translations.translate,
    columns = {
      dropdownMenu: {
        colId: 'dropdownMenu',
        sortOrder: 100,
        cellRenderer: 'CellMenu',
        headerName: '',
        headerClass: ['ps-2', 'pt-1'],
        maxWidth: 25,
        width: 25,
        minWidth: 25,
        remove: !currentPermissionsByType.pos_item_manage,
        cellClass: ['menu-cell'],
        cellRendererParams: { excludeFromExport: true },
        sortable: false,
        suppressColumnsToolPanel: true,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        forceOverrideValuegetter: true,
        valueGetter: params => ({
          group: params.node.group,
          items: [
            {
              label: translations.txtPOSItemEdit,
              onClick: openEditPOSItem,
              value: params.data && params.data.posItem.id
            },
            {
              label: translations.txtGenericArchive,
              value: [params.data && params.data.posItem.id],
              onClick: () => {
                save({ value: true, id: params.data.posItem.id, type: 'archive' })
              },
              hide: params.data && params.data.posItem.archived
            },
            {
              label: translations.txtGenericRestore,
              value: [params.data && params.data.posItem.id],
              onClick: () => {
                save({ value: false, id: params.data.posItem.id, type: 'archive' })
              },
              hide: !params.data || !params.data.posItem.archived
            }
          ]
        })
      },
      checkboxSelection: {
        headerTooltip: translations.txtGenericSelectAll,
        headerClass: ['checkbox-cell'],
        headerWidth: 0,
        minWidth: 20,
        width: 20,
        maxWidth: 20,
        sortOrder: 200,
        remove: !currentPermissionsByType.pos_item_manage,
        colId: 'checkboxSelection',
        cellClass: ['checkbox-cell'],
        checkboxSelection: params => !params.node.group && params.data && !params.data.wiskRowHidden,
        sortable: false,
        suppressColumnsToolPanel: true,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        suppressMovable: true,
        cellRendererParams: { excludeFromExport: true },
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true
      },
      posItemImage: {
        colId: 'posItemImage',
        sortOrder: 300,
        headerName: translations.txtGenericImage,
        headerClass: ['hide-header-label'],
        field: 'image',
        cellRenderer: 'CellImage',
        maxWidth: 50,
        hide: true,
        width: 50,
        minWidth: 50,
        sortable: false,
        cellRendererParams: {
          excludeFromExport: true,
          overlayIcon: 'wisk-edit',
          filterDisplay: 'tinyThumb',
          onClick: data => {
            if (!data.wiskRowHidden) {
              setGlobalAction({
                type: 'fileUpload',
                action: {
                  fileTypesAccept: 'image/*',
                  useGoogle: true,
                  target: data,
                  save: value => {
                    save({ value, id: data.posItem.id, type: 'image', previousValue: data.posItem.image })
                  }
                }
              })
            }
          }
        },
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        cellClass: ['image-cell'],
        valueGetter: params => ({
          image: (params.data && params.data.posItem.image) || '3d5df52e-18ea-4d36-9d26-7b3059f73a5f.png'
        })
      },
      image: {},
      title: {},
      allergens: {},
      distributor: {},
      distributorCode: {},
      pluNumber: {
        headerName: translations.txtGenericPOSCode,
        cellRenderer: 'CellText',
        colId: 'pluNumber',
        sortOrder: 300,
        cellClass: ['text-center', 'stringTypeCellStyle'],
        minWidth: 80,
        width: 120,
        maxWidth: 300,
        enableRowGroup: true,
        cellRendererParams: {
          translate: translations.translate,
          updateValue: save,
          type: 'plu_number'
        },
        valueGetter: params => {
          let value = get(params, 'data.posItem.plu_number', '')

          // if (!value && params.node.group) {
          //   let leafs = getLeafNodesRecursive(params.node)
          //   value = get(leafs[0], 'data.posItem.plu_number', '')
          // }

          return {
            id: params.data && params.data.posItem.id,
            readonly: params.data && !params.data.posItem.editable,
            input_value: value,
            group: params.node.group
          }
        }
      },
      menuPrice: {
        headerName: `${translations.txtGenericPOSItem} ${translations.columnMenuPrice}`,
        colId: 'menuPrice',
        minWidth: 130,
        sortOrder: 1500,
        width: 100,
        cellClass: ['text-end', 'pe-2', 'currency'],
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => {
          if (!params.data && params.node.group && (get(params, 'node.rowGroupColumn.colId') === 'posItemTitle' || get(params, 'node.rowGroupColumn.colId') === 'pluNumber')) {
            const nodes = getLeafNodesRecursive(params.node)
            return get(nodes[0], 'data.posItem.price', 0)
          }
          return get(params, 'data.posItem.price', 0)
        }
      },
      cost: {
        sortOrder: 1500,
        colId: 'cost',
        width: 100,
        headerName: translations.txtPOSItemIngredientCost,
        cellClass: ['text-end', 'pe-2', 'currency'],
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => get(params, 'data.ingredient.cost', 0)
      },
      posItemcost: {
        headerName: translations.txtPOSItemTotalPOSItemCost,
        sortOrder: 1600,
        colId: 'posItemCost',
        width: 100,
        cellClass: ['text-end', 'pe-2', 'currency'],
        cellRendererParams: { decimals: 2 },
        valueFormatter: params => currencyFormatHideZero(params.value),
        valueGetter: params => {
          if (!params.data && params.node.group && (get(params, 'node.rowGroupColumn.colId') === 'posItemTitle' || get(params, 'node.rowGroupColumn.colId') === 'pluNumber')) {
            const nodes = getLeafNodesRecursive(params.node)
            return get(nodes[0], 'data.posItem.cost', 0)
          }
          return get(params, 'data.posItem.cost', 0)
        }
      },
      costPercentage: {
        headerName: translations.txtPOSItemPercentageOfTotalCost,
        cellClass: ['text-end', 'pe-2'],
        sortOrder: 1600,
        colId: 'costPercentage',
        width: 100,
        cellRendererParams: { useValueFormatter: true },
        valueFormatter: params => (!params.data && params.node.group ? '' : percentageFormat(params.value)),
        valueGetter: params => get(params, 'data.ingredient.stats.cost.percentage', 0)
      },
      posItemcostPercentage: {
        headerName: translations.txtPOSItemCostPercentage,
        cellClass: ['text-end', 'pe-2'],
        sortOrder: 1600,
        colId: 'posItemCostpercentage',
        minWidth: 120,
        cellRendererParams: { useValueFormatter: true },
        valueFormatter: params => (params.value === 0 ? '' : percentageFormat(params.value)),
        valueGetter: params => {
          if (!params.data && params.node.group && (get(params, 'node.rowGroupColumn.colId') === 'posItemTitle' || get(params, 'node.rowGroupColumn.colId') === 'pluNumber')) {
            const nodes = getLeafNodesRecursive(params.node)
            return get(nodes[0], 'data.posItem.stats.cost.percentage', 0)
          }
          return get(params, 'data.posItem.stats.cost.percentage', 0)
        }
      },
      servingSize: {
        colId: 'servingSize',
        cellRenderer: 'CellText',
        sortOrder: 700,
        width: 100,
        maxWidth: 160,
        headerName: translations.txtPOSItemsServingSize,
        cellRendererParams: {
          readonly: true,
          infoComponentType: 'extraText'
        },
        valueGetter: params => {
          if (params.node.group) return ''
          const servingSize = get(
            params,
            'data.ingredient.serving_size',
            servingSizes.find(s => s && s.type === 'custom')
          )
          let extraText = `${get(servingSize, 'measurement.quantity')} ${get(servingSize, 'measurement.unit_of_measurement')}`
          return {
            input_value: servingSize.type,
            extraText
          }
        }
      },
      posItemTitle: {
        headerName: translations.txtGenericPOSItem,
        cellRenderer: 'CellText',
        colId: 'posItemTitle',
        minWidth: 100,
        width: 150,
        sortOrder: 400,
        maxWidth: 300,
        sort: 'asc',
        enableRowGroup: true,
        keyCreator: params => params.value && params.value.input_value,
        cellRendererParams: { translate: translations.translate, readonly: true, siblingsVisibleInGroup: ['pluNumber'] },
        valueGetter: params => {
          let value = get(params, 'data.posItem.title', '')

          if (!value && params.node.group) {
            let leafs = getLeafNodesRecursive(params.node)
            value = get(leafs[0], 'data.posItem.title', '')
          }

          return {
            id: params.data && params.data.posItem.id,
            input_value: value
          }
        }
      },
      posItemFamily: {
        headerName: translations.txtGenericFamily,
        colId: 'posItemFamily',
        hide: true,
        cellClass: ['grid-text-span', 'text-center'],
        sortOrder: 900,
        width: 100,
        minWidth: 80,
        sort: 'asc',
        cellRenderer: 'CellText',
        columnGroupShow: 'open',
        cellRendererParams: { readonly: true },
        keyCreator: params => params.value && params.value.input_value,
        enableRowGroup: true,
        valueGetter: params => {
          let posItemCategory = (params && params.data && params.data.posItem.category) || {},
            value = posItemCategory.family || posItemCategory.primary_family || translations.txtGenericUnknown
          return { input_value: value }
        }
      },
      posItemCategory: {
        headerName: translations.txtGenericCategory,
        colId: 'posItemCategory',
        hide: true,
        cellClass: ['grid-text-span', 'text-center'],
        sortOrder: 1000,
        width: 100,
        minWidth: 80,
        sort: 'asc',
        cellRenderer: 'CellText',
        columnGroupShow: 'open',
        cellRendererParams: { readonly: true },
        keyCreator: params => params.value && params.value.input_value,
        enableRowGroup: true,
        valueGetter: params => {
          let posItemCategory = get(params, 'data.posItem.category', {}),
            value = posItemCategory.category || posItemCategory.primary_category || translations.txtGenericUnmapped

          return { input_value: value }
        }
      },
      posCategoryMenu: {
        headerName: translations.txtPosItemsGridPosCategoryMenu,
        colId: 'posCategoryMenu',
        hide: true,
        cellClass: ['grid-text-span'],
        sortOrder: 1100,
        width: 100,
        remove: !hasPosMenuCategory,
        minWidth: 80,
        sort: 'asc',
        cellRenderer: 'CellText',
        columnGroupShow: 'open',
        cellRendererParams: { readonly: true },
        keyCreator: params => params.value && params.value.input_value,
        enableRowGroup: true,
        valueGetter: params => ({ input_value: get(params, 'data.posItem.pos_menu_category.title', translations.txtGenericUnmapped) })
      },
      posCategorySale: {
        headerName: translations.txtPosItemsGridPosCategorySale,
        colId: 'posCategorySale',
        hide: true,
        cellClass: ['grid-text-span'],
        sortOrder: 1200,
        width: 100,
        remove: !hasPosSaleCategory,
        minWidth: 80,
        sort: 'asc',
        cellRenderer: 'CellText',
        columnGroupShow: 'open',
        cellRendererParams: { readonly: true },
        keyCreator: params => (params.value && params.value.input_value) || translations.txtGenericGroupTitleMissing,
        enableRowGroup: true,
        valueGetter: params => ({ input_value: get(params, 'data.posItem.pos_sale_category.title') })
      },
      lastSoldDate: {
        headerName: translations.txtPosItemsLastSoldDate,
        colId: 'lastSoldDate',
        ...agGridFilters.date,
        hide: true,
        sortOrder: 1500,
        valueFormatter: params => formatDate(params.value),
        valueGetter: params => get(params, 'data.posItem.last_sale_date')
      },
      more: {
        colId: 'more',
        sortOrder: 27000,
        headerName: translations.columnDetails,
        minWidth: 100,
        width: 100,
        cellClass: ['cell-more-details'],
        suppressColumnsToolPanel: true,
        suppressSortingToolPanel: true,
        suppressFiltersToolPanel: true,
        cellRenderer: 'CellMoreDetails',
        cellRendererParams: {
          excludeFromExport: true,
          onClick: openEditPOSItem
        },
        valueGetter: params => ({
          id: (params.data && params.data.posItem.id) || '',
          group: !!params.node.group
        })
      }
    }

  let columnsArray = Object.values(columns),
    customFieldsColumns = setupCustomFieldsAsColumns({ customFields, translate, save, agGridFilters, columns: columnsArray })

  customFieldsColumns.forEach(column => {
    columns[column.colId] = column
  })

  return columns
}

export { getGridColumns, getFlatGridColumns, getItemsGridColumns }
