<template>
  <div class="w-100">
    <wiskGrid v-bind="$attrs" :gridStyle="gridStyle" :columnDefs="columnDefs" :rowData="localItemsComputed" @requestFilter="$emit('requestFilter', $event)" @rowNodes="rowNodes = $event"
      :gridOptions="gridOptions" :loadingOverlay="loading" @selectedRowsChanged="$emit('selectedRowsChanged', $event)" parentGridName="POSItemsGrid" groupCheckboxSelection
      @visibleRows="$emit('visibleRows', $event)" @displayedColumnsChanged="displayedColumnsChanged" @gridApi="gridReady" :header="header" :customFilter="customFilter" :defaultFilter="defaultFilter">

      <template v-slot:additional-header-controls>
        <slot></slot>
      </template>
    </wiskGrid>
    <confirm ref="confirmDialog">
      <h5 v-for="(line, index) in ingredientsToImport" :key="index">
        {{ line }}
      </h5>
    </confirm>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { guid, preparePOSItem, objectFilter, getPOSItemIngredientDescription } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'
import api from '@/api'
import { getGridColumns } from './columnDefs'

export default {
  name: 'POSItemsGrid',
  emits: ['gridApi', 'openEditPOSItem', 'visibleRows', 'selectedRowsChanged', 'requestFilter'],
  components: {
    wiskGrid
  },
  props: {
    openPOSItemEdit: { type: Boolean, default: true },
    gridStyle: {
      type: Object,
      default: () => ({ height: 'calc(100vh - 220px)' })
    },
    items: Array,
    loading: Boolean,
    defaultFilter: {
      type: Object,
      validator: value => value && typeof value.predicate === 'function',
      default: () => ({ predicate: item => item && !item.archived, name: 'active', label: 'Active', hidesArchivedItems: true })
    },
    customFilter: {
      type: Object,
      validator: value => value && typeof value.predicate === 'function'
    },
    header: {
      type: Object,
      default: () => ({})
    },
    disableSelection: Boolean
  },
  data() {
    return {
      gridApi: null,
      gridOptions: null,
      preparePOSItemsDebounceId: null,
      columnDefs: [],
      hasIngredientsColumn: false,
      onDemandStateIdPOSItemCost: guid(),
      ingredientsToImport: [],
      rowNodes: []
    }
  },
  computed: {
    ...mapGetters(['venue', 'configDataLoaded', 'posIntegrationSettings']),
    ...mapState(['customFieldsByTarget', 'translations', 'allergens', 'bottlesById', 'firestoreInitialLoadComplete', 'itemPricesById', 'subrecipesById', 'currentPermissionsByType', 'posItemCostsById']),
    localItemsComputed() {
      return this.items.map(posItem => preparePOSItem(posItem, this.$store.state))
    }
  },
  methods: {
    ...mapActions(['setPOSItem', 'setGlobalAction', 'setOnDemandState']),
    gridReady(gridApi) {
      this.gridApi = gridApi
      this.$emit('gridApi', gridApi)
    },
    deselectAll() {
      this.gridApi && this.gridApi.deselectAll()
    },
    openEditPOSItem(id) {
      this.$emit('openEditPOSItem', id)

      if (this.openPOSItemEdit) {
        this.setGlobalAction({ type: 'posItemEdit', action: { id } })
      }
    },
    updateValue(value, id, type) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.setPOSItem({
              id,
              operation: { type, value }
            })
          },
          message: value ? this.translations.confirmArchiveText : this.translations.confirmRestoreText,
          title: value ? this.translations.confirmArchiveTitle : this.translations.confirmRestoreTitle,
          autoConfirm: type !== 'archive'
        })
      }
    },
    save({ value, id, type, previousValue }) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.setPOSItem({
              id,
              operation: { type, value, from: previousValue }
            })
          },
          message: value ? this.translations.confirmArchiveText : this.translations.confirmRestoreText,
          title: value ? this.translations.confirmArchiveTitle : this.translations.confirmRestoreTitle,
          autoConfirm: type !== 'archive'
        })
      }
    },
    createColumns() {
      if (this.configDataLoaded) {
        this.columnDefs = getGridColumns({
          hasPosMenuCategory: this.posIntegrationSettings.is_menu_category_supported,
          hasPosSaleCategory: this.posIntegrationSettings.is_sale_category_supported,
          translations: this.translations,
          setGlobalAction: this.setGlobalAction,
          save: this.save,
          openEditPOSItem: this.openEditPOSItem,
          allergens: this.venue.venue_type === 'food' ? this.allergens : null,
          currentPermissionsByType: this.currentPermissionsByType,
          customFields: (this.customFieldsByTarget && this.customFieldsByTarget.pos_item) || [],
          importIngredientsFromPOSItems: this.importIngredientsFromPOSItems,
          cloneAsDraftPOSItem: this.cloneAsDraftPOSItem,
          disableSelection: this.disableSelection
        })
      }
    },
    getRowHeight(params) {
      return (this.hasIngredientsColumn && params && params.data && params.data.ingredients && params.data.ingredients.length * 35) || 35
    },
    displayedColumnsChanged() {
      if (this.gridApi) {
        let columns = this.gridApi.getAllGridColumns()

        this.hasIngredientsColumn = columns.some(col => col.colId === 'ingredients')

        this.gridApi.resetRowHeights()
      }
    },
    importIngredientsFromPOSItems(rows) {
      console.log(rows)
      this.setGlobalAction({
        type: 'posItemSearchDialog',
        action: {
          message: this.translations.txtPOSItemsImportIngredientsFromSearch,
          customFilter: (posItem, query) => posItem && !posItem.archived && posItem.ingredients.length && objectFilter({ payload: posItem, query, stopAtLevel: 1 }),
          onChange: found => {
            if (found && found.id) {
              this.ingredientsToImport = found.ingredients.map(ingredient => {
                let item = this.bottlesById[ingredient.item_id] || { title: '' },
                  subrecipe = this.subrecipesById[ingredient.subrecipe_id]

                return getPOSItemIngredientDescription({ ...ingredient, item, subrecipe })
              })
              this.updateBatchGeneric({ type: 'import_ingredients_from', value: found.id }, rows)
            }
          }
        }
      })
    },
    cloneAsDraftPOSItem(posItemId) {
      if (posItemId && this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            api.clonePOSItemAsDraft(posItemId).then((response) => {
              this.setGlobalAction({ type: 'posItemEdit', action: { id: response.id } })
            })
          },
          message: this.translations.txtPOSItemCloneAsDraftConfirmationMessage,
          title: this.translations.txtPOSItemCloneAsDraft
        })
      }
    },
    updateBatchGeneric(operation, rows) {
      if (operation && this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            api.updatePOSItemsBatch({ pos_item_ids: rows.map(item => item && item.id), operation }).then(() => {
              this.deselectAll()
              this.ingredientsToImport = []

              if (this.gridApi) {
                this.gridApi.resetRowHeights()
              }
            })
          },
          cancelCallback: () => {
            this.ingredientsToImport = []
          },
          message: this.translations.txtPOSItemsImportIngredientsConfirm,
          title: this.translations.confirmItemsGridFloatingActionTitle
        })
      }
    },
    //called from outside
    getNextRowNode(nodeId) {
      let node = this.gridApi.getRowNode(nodeId)

      if (node && node.rowIndex < this.rowNodes.length - 1) {
        return this.rowNodes[node.rowIndex + 1]
      }
      return null
    },
    //called from outside
    getPreviousRowNode(nodeId) {
      let node = this.gridApi.getRowNode(nodeId)

      if (node && node.rowIndex > 0) {
        return this.rowNodes[node.rowIndex - 1]
      }
      return null
    }
  },
  created() {
    this.gridOptions = {
      headerHeight: 30,
      getRowHeight: this.getRowHeight,
      rowClassRules: {
        'attention-row': 'data && data.unmapped',
        'warning-row': 'data && data.archived'
      }
    }
  },
  beforeUnmount() {
    this.gridApi = null
    // this.setOnDemandState({ id: this.onDemandStateIdPOSItemCost, type: 'pos_item_cost', REMOVE: true })
  },
  watch: {
    localItemsComputed() {
      this.gridApi && this.gridApi.resetRowHeights()
    },
    customFieldsByTarget: 'createColumns',
    configDataLoaded: {
      handler: 'createColumns',
      immediate: true
    },
    customFilter() {
      this.deselectAll()
    }
  }
}
</script>

<style>
.percentage-warning {
  background-color: var(--bs-warning) !important;
}
</style>
