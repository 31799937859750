<template>
  <div class="grid-multiselect-input-container">
    <label v-if="label">{{ label }}</label>
    <b-button size="xs" v-if="useButtonForSave" variant="primary" @click="$emit('input', localValue); hasChange = false" class="grid-multiselect-input-save" :disabled="!hasChange">
      {{ translations.txtGenericSave }}
    </b-button>

    <wiskGrid ref="wiskGrid" @gridApi="gridReady" :rowData="items" :columnDefs="columnDefs" @selectedRowsChanged="selectedRowsChanged" :parentGridName="parentGridName"
      :trackBy="trackBy" gridAutoHeight :gridOptions="gridOptions" @modelUpdated="onValueChange" noFloatingPanel @firstDataRendered="firstDataRendered" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import isEqual from 'lodash.isequal'
import wiskGrid from '@/components/grids/WiskGrid'

export default {
  name: 'GridMultiSelectInput',
  emits: ['input', 'gridApi'],
  components: { wiskGrid },
  props: {
    items: Array,
    value: {
      type: Array,
      default: () => []
    },
    label: String,
    titleLabel: String,
    trackBy: { type: String, default: 'id' },
    className: String,
    prefix: String,
    suffix: String,
    parentGridName: { type: String, required: true },
    useButtonForSave: Boolean
  },
  data() {
    return {
      gridApi: null,
      localValue: [],
      stopListen: false,
      hasChange: false
    }
  },
  computed: {
    ...mapState(['translations']),
    ...mapGetters([]),
    columnDefs() {
      return [
        {
          colId: 'checkboxSelection',
          sortOrder: 200,
          headerName: '',
          headerClass: ['pt-1'],
          minWidth: 30,
          width: 30,
          maxWidth: 30,
          cellClass: ['checkbox-cell'],
          checkboxSelection: params => !params.node.group && params.data && !params.data.wiskRowHidden && !params.data.disabled,
          cellRendererParams: {
            excludeFromExport: true
          },
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        { colId: 'title', sortOrder: 300, headerClass: ['pt-2'], headerName: this.titleLabel || this.translations.txtGenericTitle, field: 'title' }
      ]
    },
    gridOptions() {
      return {
        getRowId: params => `${params?.data?.[this.trackBy]}`,
        onRowClicked: e => {
          if (!e?.node?.data?.disabled) {
            e.event.stopPropagation()
            e.node.setSelected(!e.node.isSelected())
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    gridReady(agGridApi) {
      this.gridApi = agGridApi

      this.$emit('gridApi', agGridApi)
    },
    selectedRowsChanged(rows) {
      this.localValue = rows.map(r => r[this.trackBy])
    },
    firstDataRendered() {
      setTimeout(() => {
        this.onValueChange()
      }, 0)
    },
    onValueChange() {
      if (this.gridApi && Array.isArray(this.value) && !isEqual(this.localValue, this.value)) {
        this.stopListen = true

        this.gridApi.forEachLeafNode(node => {
          node.setSelected(false)

          let id = node.data && node.data.id
          if (id && this.value.includes(id)) {
            node.setSelected(true)
          }
        })

        setTimeout(() => {
          this.stopListen = false
        }, 0)
      }
    }
  },
  beforeUnmount() {
    this.gridApi = null
  },
  watch: {
    gridApi: 'onValueChange',
    items: 'onValueChange',
    value: 'onValueChange',
    localValue: {
      immediate: true,
      deep: true,
      handler() {
        if (!isEqual(this.localValue, this.value)) {
          this.hasChange = true

          if (!this.useButtonForSave && !this.stopListen && this.gridApi) {
            this.$emit('input', this.localValue)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.grid-multiselect-input-container {

  .grid-multiselect-input-save {
    position: absolute;
    right: 20px;
    top: 5px;
    z-index: 1;
  }

  label {
    position: absolute;
    left: 10px;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    color: #999999;
    pointer-events: none;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 1;
    background-color: white;
    border-radius: 0.25rem;
    padding: 0 5px;
    transform: scale(0.9) translateY(-70%) translateX(10px);
    transform-origin: 0 0;
    white-space: nowrap;
    z-index: 1;
  }

}
</style>
