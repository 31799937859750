<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'distributorEdit', action: null })" size="lg" :warning="!!distributor?.archived"
    :hideFooter="!!distributor?.id" :title="modalTitle">

    <template v-slot:modal-header-extra>
      <div class="w-100" v-if="distributor && distributor.id">
        <b-form-radio-group class="float-start" buttons v-model="selectedEditorTab" button-variant="outline-primary">
          <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
          <b-form-radio :value="1"> {{ translations.txtGenericTimeline }} </b-form-radio>
        </b-form-radio-group>
      </div>
      <wiskActions :title="translations.txtGenericActions" :actions="pageActions" v-if="distributor?.id" />
    </template>

    <div v-if="distributor" class="min-h-440">
      <div v-if="selectedEditorTab === 0">
        <distributorOperations v-model="operations" v-if="!distributor.id" :distributor="distributor" @validStateChanged="valid = $event" class="px-0" @wiskDistributorOperations="wiskDistributorOperations = $event" />

        <distributorOperations v-if="distributor.id" @update:modelValue="save" @linkDistributor="openWiskDistributorSelector" :distributor="distributor" @validStateChanged="valid = $event" class="px-0" />

        <fieldset v-if="distributor.contacts" style="box-shadow: none;" class="px-">
          <legend>
            {{ translations.txtDistributorContacts }}
          </legend>

          <contactsGrid :distributor="distributor" class="mb-3" />

          <contactAdd @addContact="save" v-if="distributor.id" />
        </fieldset>

        <wiskLoading :loading="loading" />
      </div>
      <div v-if="selectedEditorTab === 1">
        <timeline parentGridName="DistributorEditTimeline" :filters="[{ type: 'distributor', id: distributor.id }]" />
      </div>
    </div>

    <wiskModal v-model="retryArchiveVisible" size="lg" hideHeaderExtra :title="translations.txtGenericErrors"
      :okText="translations.txtGenericRetry" @ok="archive">
      <h5 class="mb-4 text-danger">
        {{ translations.txtDistributorsArchiveError }}
      </h5>
      <wiskItemsGrid class="distributor-operation-error-handler" trackBy="item_distributor_id" :columns="itemsBlockingArchiveColumns"
        :items="itemsBlockingArchive" parentGridName="ItemsBlockingDistributorArchive" gridAutoHeight />
    </wiskModal>

    <wiskDistributorFind :distributor="distributor" v-if="distributor && distributor.id && !distributor.wisk_distributor" :visible="publicDistributorSelectorVisible"
      @hide="publicDistributorSelectorVisible = false" @select="onWiskDistributorSelected" />

    <confirm ref="confirmDialog" />

    <template v-slot:modal-footer>
      <editorButtons :editorValid="valid" :previous="editAction && editAction.previous" :next="editAction && editAction.next"
        :close="distributor && distributor.id ? () => { setGlobalAction({ type: 'distributorEdit', action: null }) } : undefined"
        :save="distributor && !distributor.id ? save : undefined" :archive="distributor && distributor.id && !distributor.archived && archive"
        :restore="distributor && distributor.archived && restore" />
    </template>
  </wiskModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import get from 'lodash.get'
import { compareNumbers, prepareVariantIdAsItem } from '@/modules/utils'
import distributorOperations from '@/components/distributors/DistributorOperations'
import contactsGrid from '@/components/distributors/DistributorContactsGrid'
import contactAdd from '@/components/distributors/DistributorContactAdd'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import timeline from '@/components/timeline/Timeline'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import wiskDistributorFind from '@/components/distributors/WiskDistributorFind'
import api from '@/api'

export default {
  name: 'DistributorEdit',
  components: { distributorOperations, editorButtons, contactsGrid, contactAdd, timeline, wiskItemsGrid, wiskDistributorFind },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      selectedEditorTab: 0,
      wiskDistributorOperations: [],
      operations: [],
      valid: false,
      loading: false,
      itemsBlockingArchive: [],
      retryArchiveVisible: false,
      publicDistributorSelectorVisible: false,
      itemsBlockingArchiveColumns: { image: { hide: false }, title: { hide: false }, measurement: {}, distributor: { hide: false } }
    }
  },
  computed: {
    ...mapState(['translations', 'distributorsById', 'bottlesById', 'currentPermissionsByType']),
    pageActions() {
      return [
        { key: 20, title: this.translations.txtDistributorPublincLink, icon: 'wisk-search', variant: 'primary', hide: !!this.distributor.wisk_distributor, action: this.openWiskDistributorSelector },
        { key: 30, title: this.translations.txtDistributorPublincLinkNot, icon: 'wisk-delete', variant: 'danger', hide: !this.distributor.wisk_distributor, action: this.unlinkPublicDistributor },
        { key: 85, title: this.translations.txtCustomFieldsView, icon: 'wisk-options', variant: 'primary', action: this.openCustomFields },
        { key: 100, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.distributor.archived, action: this.archive },
        { key: 150, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.distributor.archived, action: this.restore }
      ]
    },
    modalTitle() {
      if (this.distributor) {
        let archived = this.distributor.archived ? ` - (${this.translations.txtGenericArchived})` : ''
        return this.distributor.id ? `${this.distributor.title} (${this.translations.txtMovementFlatAggregateOnDistributor})${archived}` : this.translations.txtGenericNewDistributor + archived
      }
      return ''
    },
    distributor() {
      if (this.editAction) {
        if (this.editAction.id) {
          return this.distributorsById[this.editAction.id]
        }

        return { id: 0, title: this.editAction.title || '' }
      }

      return null
    }
  },
  methods: {
    ...mapActions(['setDistributor', 'setGlobalAction']),
    onWiskDistributorSelected(wiskDistributor) {
      if (wiskDistributor) {
        this.save([{ type: 'wisk_distributor_id', value: wiskDistributor.id }])
      }
    },
    openWiskDistributorSelector() {
      this.publicDistributorSelectorVisible = true
    },
    openCustomFields() {
      this.setGlobalAction({ type: 'customFields', action: { target: 'distributor' } })
    },
    unlinkPublicDistributor() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save([{ type: 'wisk_distributor_id_clear' }])
          },
          message: this.translations.confirmUnlinkPublicDistributorText,
          title: this.translations.confirmUnlinkPublicDistributorTitle,
        })
      }
    },
    save(operations) {
      if (this.valid || operations) {
        this.loading = true
        let wiskDistributorPromise = Promise.resolve(),
          operation = operations || this.operations

        console.log('operation', JSON.parse(JSON.stringify(operation)), this.operations)

        if (this.wiskDistributorOperations && this.wiskDistributorOperations.length && !this.distributor.id) {
          wiskDistributorPromise = api.updateWiskDistributor(0, this.wiskDistributorOperations)
        }

        wiskDistributorPromise.then(wiskDistributor => {
          if (wiskDistributor) {
            operation = [...operation, { type: 'wisk_distributor_id', value: wiskDistributor.id }]
          }

          this.setDistributor({ id: this.distributor.id, operation })
            .then(distributor => {
              this.operations = []
              this.retryArchiveVisible = false
              this.loading = false

              if (!operations) {
                this.setGlobalAction({ type: 'distributorEdit', action: { id: distributor.id }, preventMultiple: true })
              }

              if (typeof this.editAction.onChange === 'function') {
                this.editAction.onChange(distributor)
              }
            })
            .catch(error => {
              let usedBy = get(error, 'data.info.used_by')
              if (usedBy) {
                this.itemsBlockingArchive = usedBy
                  .map(variantId => prepareVariantIdAsItem(variantId, this.$store.state))
                  .sort((a, b) => compareNumbers(a.item_id, b.item_id))

                this.retryArchiveVisible = true
              } else {
                this.operations = []
              }
              this.loading = false
            })
        })

      }
    },
    archive() {
      this.save([{ type: 'archive', value: true }])
    },
    restore() {
      this.save([{ type: 'archive', value: false }])
    }
  },
  watch: {
    operations(operations) {
      console.log('operations', operations)
    },
    bottlesById() {
      if (this.retryArchiveVisible && this.itemsBlockingArchive && this.itemsBlockingArchive.length) {
        this.itemsBlockingArchive = this.itemsBlockingArchive.filter(i => i).map(item => prepareVariantIdAsItem(item.item_distributor_id, this.$store.state))
      }
    }
  }
}
</script>
<style lang="scss"></style>
