<template>
  <b-container fluid class="ingredient-container px-0" :id="domId">
    <b-row>
      <b-col>
        <wiskInputGroup @errorCountChanged="setValidState" :loading="loading" :disabled="disabled">
          <b-row :class="{ 'flex-column': vertical }">
            <b-col cols="1" :md="vertical ? 12 : 1" class="d-none d-md-block text-center">
              <imageView :src="imgSrc" v-if="imgSrc" />
            </b-col>
            <b-col cols="12" :md="vertical ? 12 : 3" class="px-0-m pinput ">
              <!-- autofocus let's see if somebody complains about not having autofocus, it breaks keyboard navigation-->
              <wiskInput infoTooltipKey="b9bca837-e916-4fb9-80bc-6d3c7b97aa39" :key="key" :modelValue="selectedIngredientItem ? selectedIngredientItem.title : ''"
                @autocompleteInput="onAutocompleteInput" @autocompleteSelected="onAutocompleteSelected" autocompleteOpenAbove :autocompleteMinChars="1" :autocompleteItemFormatter="addSuffix"
                autocompleteDisplayKey="title" :autocompleteItems="autocompleteItems" @clear="onAutocompleteSelected(null)" autofocus
                :label="translations.txtPOSItemsSearchIngredient" autocomplete id="pos-item-add-ingredient-autocomplete" :suffix="(selectedIngredientItem && selectedIngredientItem.titleSuffix) || ''">
                <div v-if="newBottleVisible">
                  <b-row class="my-2">
                    <b-col>
                      {{ translations.txtVenueBottlesNotFoundCreate }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-button size="sm" @click="createNewItem">
                        {{ translations.txtVenueBottlesCreate }}
                      </b-button>
                    </b-col>
                    <b-col>
                      <b-button size="sm" @click="createNewSubrecipe">
                        {{ translations.txtSubrecipesNew }}
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </wiskInput>
            </b-col>
            <b-col cols="12" :md="vertical ? 12 : 7" :class="{ 'px-0': !vertical }">
              <b-row :class="{ 'flex-column': vertical }">
                <b-col cols="12" :md="vertical ? 12 : 4" class="pinput px-0-m">
                  <wiskSelect infoTooltipKey="8963be17-499c-4141-90e4-c64ec5ea3e8b" :multiselectOptions="multiselectOptionsServingSize" :label="translations.txtPOSItemsServingSize" inputClass="w-100"
                    :modelValue="selectedServingSize" operation="serving_size" @change="onServingSizeInput" :items="availableServingSizes"
                    :addNewItem="addServingSizeConfig" :disabled="disabled" :customLabelOverride="customServingSizeLabel">
                    <template v-slot:beforeList>
                      <li class="multiselect__element" style="padding: 5px 10px;">
                        {{ translations.txtPOSItemsAvailalbeServingSizes }}
                      </li>
                    </template>
                  </wiskSelect>
                </b-col>
                <b-col cols="12" :md="vertical ? 12 : 6" :class="['pinput', { 'px-0': !vertical }]">
                  <measurement :modelValue="selectedServingSize.measurement" @update:modelValue="onServingSizeInput({ id: -1, measurement: $event, ...$event, title: 'Custom', type: 'custom' })"
                    :disabled="(disabled || (selectedServingSize && selectedServingSize.id > 0))" v-if="selectedServingSize" horizontal inline :preferredType="measurementType"
                    :extraUnitsOfMeasurement="[{ id: 'unit', title: 'Unit', units_of_measurements: [{ id: 'unit', short: 'unit', title: 'Unit', type: 'unit' }] }]"
                    :label="translations.txtVenueBottlesMeasurement" :disableTypeChange="!!selectedIngredientItem" />
                </b-col>
                <b-col cols="12" :md="vertical ? 12 : 2" v-if="selectedServingSize && selectedServingSize.type === 'custom'" class="pinput middle-center" style="align-items: flex-start;">

                  <b-button :disabled="!valid" style="flex: 1; padding-right: 5px; height: 40px" size="sm" class="text-primary" variant="link" @click="add"
                    ref="buttonAdd" v-tooltip="translations.txtGenericAddIngredient">
                    <icon style="margin-right: 5px; margin-bottom: 1px;" name="wisk-check" :scale="0.9" />
                    <span style="font-size: 16px;"> {{ translations.txtGenericAdd }} </span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </wiskInputGroup>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import get from 'lodash.get'
import merge from 'lodash.merge'
import { guid, objectFilter, compareNumbers, round } from '@/modules/utils'
import imageView from '@/components/common/ImageView'
import measurement from '@/components/bottles/Measurement'

const newIngredient = { item: {}, item_id: 0, serving_size: {} }

export default {
  name: 'AddIngredient',
  emits: ['add'],
  components: { imageView, measurement },
  props: {
    existingIngredientsIds: { type: Array, default: () => [] },
    loading: Boolean,
    disabled: Boolean,
    vertical: Boolean
  },
  data() {
    return {
      ingredient: merge({}, newIngredient),
      componentsValid: true,
      errorCounterParent: null,
      newBottleVisible: false,
      selectedIngredientItem: null,
      selectedServingSize: null,
      key: '',
      autocompleteItems: [],
      query: '',
      suffix: '',
      multiselectOptionsServingSize: {
        showLabels: false,
        preselectFirst: false,
        allowEmpty: false,
        searchable: true,
        maxHeight: 350,
        openDirection: 'above'
      },
      multiselectOptionsUnitOfMeasure: {
        maxHeight: 300,
        preselectFirst: false,
        showLabels: false,
        allowEmpty: false,
        searchable: false
      }
    }
  },
  computed: {
    ...mapGetters([]),
    ...mapState(['subrecipes', 'bottles', 'servingSizes', 'translations']),
    domId() {
      return guid()
    },
    itemsNotLinkedToSubrecipe() {
      return this.bottles.filter(b => !b.linked_subrecipe_id)
    },
    availableServingSizes() {
      let servingSizes = this.servingSizes
        .filter(s => !s.archived)
        .map(s => merge({}, s))
        .sort((a, b) => compareNumbers(a.sort_order, b.sort_order))

      if (this.measurementType) {
        servingSizes = servingSizes.filter(s => s && s.measurement && (s.measurement.type === this.measurementType || s.measurement.type === 'unit'))
      }
      return servingSizes
    },
    measurementType() {
      if (this.selectedIngredientItem && this.selectedIngredientItem.measurement) {
        return this.selectedIngredientItem.measurement.type
      }
      return null
    },
    imgSrc() {
      return (this.ingredient && this.ingredient.item && this.ingredient.item.image) || (this.selectedIngredientItem && this.selectedIngredientItem.image)
    },
    valid() {
      return this.componentsValid && this.selectedIngredientItem
    },
    addServingSizeConfig() {
      return {
        action: (id, searchQuery, value, callbackItemInjected) => {
          this.setGlobalAction({
            type: 'servingSizeEdit',
            action: {
              id: 0,
              title: searchQuery,
              measurement: { type: this.measurementType, unit_of_measurement: 'g' },
              onChange: servingSize => {
                callbackItemInjected(servingSize)
              }
            }
          })
        },
        label: this.translations.txtServingSizesAddServingSize
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    customServingSizeLabel(item) {
      return item.type === 'custom' ? item.title : `${item.title} (${item.measurement.quantity} ${item.measurement.unit_of_measurement})`
    },
    createNewItem() {
      this.setGlobalAction({
        type: 'itemEdit',
        action: {
          item_id: 0,
          // creationSource: { type: 'no such type yet' },
          title: this.query,
          onChange: item => {
            this.onAutocompleteSelected(item)
          }
        }
      })
    },
    createNewSubrecipe() {
      this.setGlobalAction({
        type: 'subrecipeEdit',
        action: {
          id: 0,
          title: this.query,
          onChange: item => {
            this.onAutocompleteSelected(item)
          }
        }
      })
    },
    onAutocompleteInput(value) {
      this.query = value

      this.autocompleteItems = [...this.itemsNotLinkedToSubrecipe, ...this.subrecipes].filter(
        b =>
          !b.archived &&
          !b.grouped_to &&
          !this.existingIngredientsIds.includes(b.id) &&
          !this.existingIngredientsIds.includes(b.item_id) &&
          objectFilter({ payload: b, query: value })
      )

      for (let i = 0; i < this.autocompleteItems.length; i++) {
        //must be a subrecipe if no item_id
        if (!this.autocompleteItems[i].item_id) {
          this.autocompleteItems[i] = {
            ...this.autocompleteItems[i],
            measurement: this.autocompleteItems[i].yields.measurement,
            titleSuffix: ` - (${round(get(this.autocompleteItems[i], 'yields.measurement.quantity'), 4)} ${get(this.autocompleteItems[i], 'yields.measurement.unit_of_measurement')}) (batch)`
          }
        }
      }

      this.newBottleVisible = !this.autocompleteItems.length
    },
    onAutocompleteSelected(bottle) {
      if (bottle) {
        this.selectedIngredientItem = bottle
        this.autocompleteItems = []

        if (this.selectedIngredientItem && this.selectedServingSize && this.selectedServingSize.id > 0) {
          this.add()
        }
      } else {
        this.selectedIngredientItem = null
        this.autocompleteItems = []
      }
    },
    setValidState(errorCount) {
      this.componentsValid = !errorCount

      if (this.componentsValid && this.$refs.buttonAdd && this.$refs.buttonAdd.classList) {
        this.$refs.buttonAdd.classList.add('attention')
      }
    },
    add() {
      if (this.selectedIngredientItem && this.selectedServingSize) {
        let servingSize = merge({}, this.selectedServingSize)

        if (servingSize.id < 0) {
          delete servingSize.id
        }
        this.$emit('add', {
          type: 'ingredient_add',
          value: {
            ingredient: {
              type: this.selectedIngredientItem.item_id ? 'item' : 'subrecipe',
              value: {
                item_id: this.selectedIngredientItem.item_id || null,
                subrecipe_id: this.selectedIngredientItem.item_id ? null : this.selectedIngredientItem.id,
              }
            },
            serving_size: servingSize
          }
        })
      }
      setTimeout(() => {
        this.selectedIngredientItem = null
        this.selectedServingSize = this.servingSizes.find(s => s.type === 'custom')
        this.key = guid()
      }, 100)
    },
    onServingSizeInput(value) {
      if (value) {
        this.selectedServingSize = { ...value }
        if (this.selectedIngredientItem && this.selectedServingSize && this.selectedServingSize.id > 0) {
          this.add()
        }
      }
    },
    addSuffix(text, item) {
      return text + item.titleSuffix
    }
  },
  mounted() {
    let parent = this.$parent
    while (parent && !this.errorCounterParent && parent !== this.$root) {
      if (parent.inputGroup) {
        this.errorCounterParent = parent
      }
      parent = parent.$parent
    }

    if (this.errorCounterParent && this.errorCounterParent.setValidState) {
      this.errorCounterParent.setValidState({ name: this.domId, hasError: false })
    }

    setTimeout(() => {
      this.selectedServingSize = this.servingSizes.find(s => s.type === 'custom')
    }, 100)
  }
}
</script>

<style lang="scss">
.archived {
  background-color: var(--bs-warning);
}

.ingredient-container {
  .pos-items-stats-container {
    .pos-items-stats {
      font-size: 0.8rem;
    }
  }
}
</style>
